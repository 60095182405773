import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "city";

export class Cities {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * get all cities
   * @param {object} params filters, limit, page size
   */
  GetCities(params) {
    let url = params != undefined ? `${API_URL}/city/${params}` : `${API_URL}/city`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * get city by rid
   * @param {string} rid
   */
  GetCity(rid) {
    let url = `${API_URL}/city/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        //console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
   * put city by rid update
   * @param {string} rid
   */
  PutCity(rid, data) {
    let url = `${API_URL}/city/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * post city create
   * @param {string} rid
   */
  PostCity(data) {
    let url = `${API_URL}/city`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * delete city remove
   * @param {string} rid
   */
  DeleteCity(rid) {
    let url = `${API_URL}/city/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  filterCityByCountry(rid){
    let url = `${API_URL}/cityfilter?country=${rid}`

      return axios.get(url)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }
}