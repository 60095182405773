import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let router = "deliveryman";
let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

export class Deliveryman {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * post deliveryman create
   * @param {string} rid
   */
  PostDeliveryman(data ) {
    let url = `${API_URL}/${router}`;
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PostDeliverymanVehicle(data, rid) {
    let url = `${API_URL}/${router}/${rid}/vehicle`;
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  validDeliveryman(data){
    let url = `${API_URL}/${router}data/${data}`;
    return axios.get(url )
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PostDeliverymanContact(data, rid) {
    let url = `${API_URL}/${router}/${rid}/contact`;
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PostBankDeliverymen(data, rid) {
    let url = `${API_URL}/${router}/${rid}/bank`;
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PutDeliveryman(data, rid) {
    let url = `${API_URL}/${router}/${rid}`;

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PutStatusDeliveryman(data, rid){
    let url = `${API_URL}/${router}/updateStatus/${rid}`;

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
    /**
     * delete deliveryman remove
     * @param {string} rid
     */
    DeleteDeliveryman(rid) {
      let url = `${API_URL}/${router}/${rid}`

      return axios.delete(url)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
    }

    GetDeliverymenFilter(rid){
      let url = `${API_URL}/deliverymanfilter?city=${rid}`;
    
        return axios.get(url)
          .then(response => response.data)
          .catch((error) => {
            return error.response.data
          })
    }

    GetDeliverymenFilterOk(filter){
      let url = `${API_URL}/deliverymanfilter${filter}`;
        console.log(url, "para filtrar")
        return axios.get(url)
          .then(response => response.data)
          .catch((error) => {
            return error.response.data
          })
    }

  /**
   * GET all Deliverymen
   * @param {object} params filters, limit, page size
   */
  GetDeliverymen(rid) {
    let url = rid == undefined ? `${API_URL}/${router}` : `${API_URL}/${router}/${rid}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  GetDeliveryManRates(rid)   {
    let url = rid == undefined ? `${API_URL}/deliveryman-rate`: `${API_URL}/deliveryman-rate/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PostDeliveryManRate(data, rid) {
    let url = rid == undefined ? `${API_URL}/deliveryman-rate`: `${API_URL}/deliveryman-rate/${rid}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PutDeliveryManRate(data, rid) {
    let url = rid == undefined ? `${API_URL}/deliveryman-rate`: `${API_URL}/deliveryman-rate/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  DeleteDeliverymanRate(rid) {
    let url = rid == undefined ? `${API_URL}/deliveryman-rate`: `${API_URL}/deliveryman-rate`

    return axios.delete(url,{data: {rid} })
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}
