import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let router = "dataphone";
let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

export class Dataphones {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * 
   * @param {object} params filters, limit, page size
   */
  GetDataphones(params) {
    let url = `${API_URL}/${router}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * put 
   * @param {string} rid
   */
  PutDataphone(data, rid) {
    let url = `${API_URL}/${router}/${rid}`;

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * 
   * @param {string} rid
   */
  PostDataphone(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * delete city remove
   * @param {string} rid
   */
  DeleteDataPhone(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}