<template src="./loading.base.component.html"></template>
<style src="./loading.base.component.css"></style>
  
<script>


export default {
  name: "loadingBase",
  components: {
  },
  props:{
    dataLoading:Boolean,
  },
  mounted(){
  },
  data() {
    return {
      active:[],
    }
  },
  watch: {
    dataLoading(val) {
      //console.log("loading has changed")
    }
  },
  methods: {
    handlerFilterCity(val){
    },
  }
}
</script>




