<template src="./side.bar.html"></template>
<style src="@/shared/global.css"></style>

<script>
import myMixin from '@/helpers/mixins.js'

export default {
  data() {
    return {
      responsive:false,
      isCollapse: true,
      sbIcon: "el-icon-caret-right",
      sideBarWidth: "230px",
      allPermissions:[],
      showCommerce: false,
      showMonitoring: false,
      showDelivery: false,
      showRol: false,
      showuser: false,
      showZone: false,
      showTypeCommerce: false,
      showtypeDocument: false,
      showCity: false,
      showCountry: false,
      showTypeInvoice: false,
      showAccountBank: false,
      showIvaRegime: false,
      showBanks: false,
      showTypeVehicle: false,
      showMeshes: false,
      showPaymentMethod: false,
      showHolidays: false
    };
  },
  mixins:[myMixin],
  mounted(){
    this.allPermissions = myMixin.methods.blockMenusToSideBar();
    window.addEventListener("resize", this.checkForResponsive);
    this.checkForResponsive();
    if ( this.allPermissions != undefined ){//Verifica los permisos para mostrarlos u ocultarlos
          this.showCommerce      = this.showItemInMenu( this.findPermisssion("commerce") );
          this.showMonitoring    = this.showItemInMenu( this.findPermisssion("monitor") );
          this.showDelivery      = this.showItemInMenu( this.findPermisssion("deliveryMan") );
          this.showRol           = this.showItemInMenu( this.findPermisssion("rol") );
          this.showuser          = this.showItemInMenu( this.findPermisssion("user") );
          this.showZone          = this.showItemInMenu( this.findPermisssion("zone") );
          this.showTypeCommerce  = this.showItemInMenu( this.findPermisssion("TypeCommerce") );
          this.showtypeDocument  = this.showItemInMenu( this.findPermisssion("typeDocument") );
          this.showCity          = this.showItemInMenu( this.findPermisssion("city") );
          this.showCountry       = this.showItemInMenu( this.findPermisssion("countries") );
          this.showTypeInvoice   = this.showItemInMenu( this.findPermisssion("typeInvoice") );
          this.showAccountBank   = this.showItemInMenu( this.findPermisssion("typeAccountBank") );
          this.showIvaRegime     = this.showItemInMenu( this.findPermisssion("IvaRegime") );
          this.showBanks         = this.showItemInMenu( this.findPermisssion("banks") );
          this.showTypeVehicle   = this.showItemInMenu( this.findPermisssion("typeVehicle") );
          this.showMeshes        = this.showItemInMenu( this.findPermisssion("mesh") );
          this.showPaymentMethod = this.showItemInMenu( this.findPermisssion("paymentMethod") );
          this.showHolidays      = this.showItemInMenu( this.findPermisssion("holidays") );
    }
  },
  props: {
    modehv: String
  },
  methods: {
    showItemInMenu(permissions){//devuelve true en caso de que el permiso
      return permissions != undefined ? ( permissions.permissions[0] == 1 ? true : false ): false;
    },
    findPermisssion( name ){//Busca un permiso por el nombre
      let permission = this.allPermissions.find( item => item.name == name);
      return permission;
    },
    checkForResponsive(){
       let w = window.innerWidth;
       if ( w <= 768 ){
         this.isCollapse = false; 
         this.responsive = true;
       }else{
         this.isCollapse = true;
         this.responsive = false;
       }
       this.$store.commit('toggleMenu', {data:true});
    },
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    Logout() {
      this.$store.commit("login", { isLogin: false });
    },
    toggleSbMenu: function() {
      this.isCollapse = !this.isCollapse;
      this.sbIcon =
        this.sbIcon == "el-icon-caret-right"
          ? "el-icon-caret-left"
          : "el-icon-caret-right";
      this.sideBarWidth = this.sideBarWidth == "230px" ? "63px" : "230px";
      this.$store.commit('toggleMenu', {data:this.isCollapse});
    }
  }
};
</script>

<style src="./side.bar.css"></style>