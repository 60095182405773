<template src="./app.html"></template>
<style src="./app.css"></style>

<script>
// @ is an alias to /src
import centralServices from "@/helpers/services.js";
import myMixin from "@/helpers/mixins.js";
import loginMiddle from "@/helpers/loginMiddle.js";
//import rabbitMQ from "@/helpers/rabbit.js";
import Login from "@/components/User/Login/login.component.vue";
import ServiceView from "@/views/Services/Service.vue";
import Report from "@/components/Dashboard/Report.vue";
import NavBar from "@/components/layout/navBar/nav.bar.component";
import Sidebar from "@/components/layout/SideBar/side.bar.vue";
import Vue from "vue";
import VueMqtt from "vue-mqtt";
import IdleVue from "idle-vue";
import { bus } from "../main.js";
import { mapActions } from "vuex";

let configApi = require('../../config.json')[process.env.NODE_ENV]
const CHANNEL_RABBIT = configApi.CHANNEL_RABBIT;

const eventsHub = new Vue();
const clientDev = "";//"dev-"
const channelDev = CHANNEL_RABBIT;//"dev."
const statusDev = "";// "dev/"
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 7200000,
});

export default {
  name: "App",
  data() {
    return {
      client_connect: undefined,
      hostname: "mq.inoutdelivery.com.co",
      port: 15676,
      stateGlobalRols: "",
      conxRabit: false,
      showNotify: 0,
      logEnterNotify: [],
      logEnterNovelty: [],
      toggleWatch: "",
      content_width: 0,
      innerWidth_window: 0,
      isCollasep: true,
      conection: false,
      showNotifications: true,
      clientId: "",
      filterAct: false,
    };
  },
  created() {
    bus.$on("isFilter", (filter) => {
      this.filterAct = filter;
      this.connectRabbit();
      // this.filterActive(filter);
    });
  },
  onIdle() {
    // Cuando el usuario está inactivo
    this.inactivityLogout();
  },
  onActive() {
    // Cuando el usuario está activo
  },
  mixins: [myMixin, centralServices, loginMiddle],
  watch: {
    "$store.state.isLogin": {
      //Si el store del login cambia verificamos que sea true para abrir la conexión de rabbit
      handler: function () {
        if (this.$store.state.isLogin == true) {
          this.connectRabbit();
        } 
        else if (this.$store.state.isLogin == false) {
          if (this.client_connect != undefined) {
            this.client_connect.disconnect();
          }
        }
      },
    },
    toggleWatch: function () {
      this.toggleSideBarWatch();
    },
  },
  methods: {

    listen: function (msg) {
      this.isCollasep = msg;
    },
    inactivityLogout() {
      // this.$store.commit("login", { isLogin: false });
      // this.$router.push("/login");
    },
    guid() {
      return (
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4() +
        "-" +
        this.s4()
      );
    },
    s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    },
    connectRabbit() {
      if (this.clientId != "") {
        return;
      }
      if (this.$store.state.user != undefined) {
        this.clientId =
          `${clientDev}spdy/${this.$store.state.user.name}@spdy.com/cms-` + this.guid();
        if (this.$store.state.authData.rabbitCredentials != undefined) {
          // this.mqrabbit(this.hostname, this.port, this.clientId);
          this.publish();
        }
        return;
      } else if (this.$store.state.user == undefined) {
        console.error("el nombre del usuario esta indefinido");
      }
    },
    publish() {
      Vue.use(VueMqtt, "wss://mq.inoutdelivery.com.co:15676/ws", {
        clean: true,
        username: "cms",
        password: "e9Y$7!UP6uhS",
        clientId: this.clientId,
        keepalive: 60000,
        properties: {
          sessionExpiryInterval: 60000,
        },
      });
      this.$mqtt.on("connect", (connack) => {
        console.log('conectado')
      });
      this.$mqtt.on("disconnect", () => {
        console.log('disco')
      });
      this.$mqtt.on("close", () => {
        console.log('close')
      });
      this.$mqtt.on("offline", async () => {
        console.log('oflline')
      });
      this.$mqtt.on("error", (error) => {
        console.log(error, 'error-rabbit')
      });
      this.$mqtt.on("reconnect", () => {

      });
  
    },

    isInLoginView() {
      return this.$store.state.isLogin == false
        ? this.$route.name == "login"
          ? true
          : false
        : false;
    },
    toggleSideBarWatch() {
      if (this.$store.state.toggle == false) {
        this.innerWidth_window = window.innerWidth;
        let sideNavBar = 180;
        this.content_width = Math.abs(sideNavBar - this.innerWidth_window);
      } else {
        this.content_width = 0;
      }
    },
    async setCommit(model) {
      //Cuando entra una notificacion en el canal de rabbit
      //;
      this.logEnterNotify = []; //Guardamos un log de las notificaiones mostradas
      let marketCoordenates = [];
      

      if (model.deliveryMan != undefined) {
        console.log(model.deliveryMan)
        marketCoordenates =
          model.deliveryMan.coords == undefined ||
            model.deliveryMan.coords == ""
            ? []
            : model.deliveryMan.coords[0].coordinates;
      }

      if (model.services != undefined && model.services.length >= 1) {
        //Canal de rabbit para los servicios
        model.services.map((itemService) => {
          //Mostramos los servicios
          if (
            this.showNotifications == true ||
            (this.showNotifications == undefined &&
              this.$store.state.isLogin == true)
          ) {
            itemService.coordsDeliveryMan = marketCoordenates;
            if (itemService.serviceState.code == "accepted") {
              this.logEnterNotify.push(itemService);
              if (itemService != undefined) {
                if (itemService.destinationLocation != undefined) {
                  itemService.destinationLocation = myMixin.methods.getArrayLocationCoordinates(
                    itemService.destinationLocation
                  ); //Convertir las coordenadas a un valor valido para el componete de mapa
                }
              }
            }
          }
        });
        this.state_notification(this.logEnterNotify);
        this.$store.commit("SET_NOTIFICATION", model.services); //Guarda los servicios entrantes dentro del store
      } else {
        //Estas son las notificaciones para los estados
        if (model.delivery != undefined && model.delivery.length >= 1) {
          this.$store.commit("DELIVERY_STATUS_CHANGE", model.delivery);
          return;
        }
        if (
          model.status != undefined ||
          (model.deliveryMan != undefined && model.status == "DISCONNECTED")
        ) {
          //Cambio en estado de un domiciliario|| model.deliveryMan != undefined
          this.$store.commit("DELIVERY_STATUS_CHANGE", model);
          return;
        }
        if (model.novelty != undefined) {
          //Novedad entrante
          let found = this.logEnterNovelty.find((itemNovelty) => {
            return itemNovelty.rid == model.rid;
          });

          if (found == undefined) {
            this.logEnterNovelty.push(model);
            this.novelty_notifiy(model);
          }
          return;
        }

        if (model.location != undefined) {
          model.destinationLocation =
            model.destinationLocation == undefined
              ? []
              : myMixin.methods.getArrayLocationCoordinates(
                model.destinationLocation
              );
          model.location.coordinates =
            model.location.coordinates == undefined
              ? []
              : myMixin.methods.getArrayLocationCoordinates(model.location);
        }

        //Inicio de la notificacion
        let notify_ = [];
        notify_.push(model);

        if (model.serviceState != undefined || model.serviceState != null) {
          this.state_notification([model]);
        }
        this.$store.commit("SET_NOTIFICATION", notify_);
        return;
      }
    },
    novelty_notifiy(novelty) {
      let date = new Date();
      let date_init = myMixin.methods.settingDate([date]);
      let time_init = myMixin.methods.settingTime([date]);

      let notify_html = `
            <div class="notify-body"> 
                  <ul>
                      <li class="state"> ${novelty.comment}: </li>
                      <li class="name">Hora: <span class="date-view">${time_init}</span></li>
                  </ul>
            </div>`;

      let notify = this.$notify({
        title: `Id servicio: #${novelty.service} `,
        message: notify_html, //novelty.comment,
        duration: 0,
        dangerouslyUseHTMLString: true,
        customClass: `notyfication`, //'${notify.serviceState.code}',
        showClose: true,
        onClick: () => {
          if (this.$route.path == "/monitoring") {
            notify.close();
            this.$store.commit("SET_NOVELTY_NOTIFY", novelty); //Notificamo a la vist
          } else {
            myMixin.methods.setAlert(
              "warning",
              "para ver los detalles, por favor dirigete a la vista de monitoreo"
            );
          }
        },
      });
    },
    state_notification(_array_) {
      _array_.map((item, index) => {
        setTimeout(() => {
          if (item.serviceState != undefined || item.serviceState != null) {
            if (item.serviceState.code == "accepted") {
              let found = this.logEnterNotify.find((element) => {
                return (
                  element.consecutive == item.consecutive &&
                  element.serviceState.code == item.serviceState.code
                );
              });

              if (found == undefined) {
                this.logEnterNotify.push(item);
                let date = new Date();
                let date_init = myMixin.methods.settingDate([date]);
                let time_init = myMixin.methods.settingTime([date]);

                let nofityBody = `<div class="notify-body"> 
                                                    <div class="notify-image" style="background-image:url('${item.deliveryMan.photo}')"> </div>
                                                    <ul>
                                                        <li class="state"> <b>${item.serviceState.name}:</b>  <span class="date-view">${time_init}</span> </li>
                                                        <li class="name">${item.deliveryMan.fullName}</li>
                                                    </ul> 
                                              </div>`;

                let notify = this.$notify({
                  title: `Servicio #${item.consecutive}`,
                  message: nofityBody,
                  duration: 8000,
                  dangerouslyUseHTMLString: true,
                  customClass: `notyfication`,
                  showClose: true,
                  onClick: () => {
                    item.handlerClick = true;
                    if (this.$route.path == "/monitoring") {
                      notify.close();
                      this.$store.commit("SET_NOTIFICATION", item);
                    } else {
                      myMixin.methods.setAlert(
                        "warning",
                        "para ver los detalles, por favor dirigete a la vista de monitoreo"
                      );
                    }
                  },
                });
              }
            }
          }
        }, 0);
      });
    },
  },
  components: {
    ServiceView,
    Login,
    Report,
    NavBar,
    Sidebar,
  },
  mounted() {
    if (this.$store.state.loginData.roles != undefined) {
      // ;
    } else {
      this.inactivityLogout();
    }
   
    // Obtén la ruta actual después de la redirección
    const currentPath = this.$route.path;
    console.log("Ruta actual después de redireccin:", currentPath);


    // Resto de tu lógica...
  
    this.logEnterNotify = [];
    
    // if (this.$route.path == '/view/service'){
     
    // }
    // else if (this.$store.state.isLogin == false) {
    //   this.$router.push("/login");
     if (this.$store.state.isLogin == true) {
      this.connectRabbit();
     
    }
  },
};
</script>