import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

export class IvaRegimes {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all IVA Regimes
   * @param {object} params filters, limit, page size
   */
  GetIvaRegimes(params) {
    let url = `${API_URL}/tax`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET all IVA Regimes2
   * @param {object} params filters, limit, page size
   */
  GetIvaRegimes2(params) {
    let url = `https://my-json-server.typicode.com/MaxJuanCa/fakeapi/IvaRegimes`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET IVA Regime by rid
   * @param {string} rid
   */
  GetIvaRegime(rid) {
    let url = `${API_URL}/tax/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT IVA Regime by rid update
  * @param {string} rid
  */
  PutIvaRegime(rid, data) {
    let url = `${API_URL}/tax/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST IVA Regime create
   * @param {string} rid
   */
  PostIvaRegime(data) {
    let url = `${API_URL}/tax`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE IVA Regime remove
   * @param {string} rid
   */
  DeleteIvaRegime(rid) {
    let url = `${API_URL}/tax/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}