import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

export class Country {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all Countries
   * @param {object} params filters, limit, page size
   */
  GetCountries(params) {
    let url = `${API_URL}/country`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET Country by rid
   * @param {string} rid
   */
  GetCountry(rid) {
    let url = `${API_URL}/country/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT Country by rid update
  * @param {string} rid
  */
  PutCountry(rid, data) {
    let url = `${API_URL}/country/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Country create
   * @param {string} rid
   */
  PostCountry(data) {
    let url = `${API_URL}/country`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE Country remove
   * @param {string} rid
   */
  DeleteCountry(rid) {
    let url = `${API_URL}/country/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}
