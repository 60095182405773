import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "monitor";

export class Monitor {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  requestService(data){
    let url = `${API_URL}/service/`;
      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }

  updateCoords(data,consecutive){
    let url = `${API_URL}/service/service-out-of-zone/${consecutive}`;
      return axios.put(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }

  getService(rid){
    let url = `${API_URL}/service/${rid}`;
    return axios.get(url, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }
  
  quote(dt){
    let url = `${API_URL}/service/quote`;
    return axios.post(url, dt, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }

  trackingDelivery(rid){
    let url = `${API_URL}/monitor/deliveryman/${rid}`;
    return axios.get(url, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        }) 
  }
  GetDeliverymenMonitor(filters){
    let url=`${API_URL}/monitor/deliverymen`;
    return axios.get(url,{ params: filters })
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        }) 
  }
  GetDeliverymenMonitorZone(rid){
    let url=`${API_URL}/monitor/zones/${encodeURIComponent(rid)}`;
    return axios.get(url)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        }) 
  }


  cancelService(data){
    let url = `${API_URL}/service/`;
      return axios.put(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }

  getCoordinatesDeliveryMan(rid){
    let url = `${API_URL}/${router}/deliverymanposition/${rid}`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  getDeliveryManCoordinates(rid){
    let url = `${API_URL}/${router}/deliverymanmonitor/${rid}`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getRouteDeliveryMan(consecutive){
    let url = `${API_URL}/${router}/deliverymandistance/${consecutive}`;
    return axios.get(url)
      .then(response => response)
      .catch((error) => {
        return error.response
    })  
  }

  sendNotificationOneSignal( notify ){
    let url = `${API_URL}/notify`;

      return axios.post(url, notify, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
      })
   }

  /**
   * get all cities
   * @param {object} params filters, limit, page size
   */
  getMonitor(id) {
    let url = id != undefined ? `${API_URL}/${router}` : `${API_URL}/${router}/${rid}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * get all Monitor deliveryman
   * @param {object} params filters, limit, page size
   */
  getMonitorDelivery(filter) {
    let url = `${API_URL}/${router}/deliveryman/new${filter}`;
    url = url.trim();
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  getMonitorDeliveryman(filter) {
    let url = `${API_URL}/${router}/deliveryman/new`;
    url = url.trim();
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * get all Monitor deliveryman
   * @param {object} params filters, limit, page size
   */
  getFilterMonitorDelivery(filter) {
    let url = `${API_URL}/${router}/deliveryman/new${filter}`; 
  
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  getDeliveryMenFromPDV(rid){
    console.log(rid)

    let url = `${API_URL}/public/${router}/deliverymanschedule/pointsale/${encodeURIComponent( rid) }`;
    console.log(url)

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      });
  }

  getAllServicesFromDelivery(id) {
    let url = `${API_URL}/${router}/filter?filter=deliveryMan.rid%3D%22%2393%3A35%22`;
    
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getMonitorZones() {
    let url = `${API_URL}/${router}/zones`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  monitorServiceFilter(filter){
    let url = `${API_URL}/${router}/filter?${filter}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * put city by rid update
   * @param {string} rid
   */
  putMonitor(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * post city create
   * @param {string} rid
   */
  postMonitor(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * delete city remove
   * @param {string} rid
   */
  deleteMonitor(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /* Metodos disponibles en el monitor */
  assignServiceToDelivery(data){
    let url = `${API_URL}/service/set-delivery-man`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getFilterMonitoring( filter ){
    let url = `${API_URL}/${router}/filter${filter}`;

    return axios.get( url )
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  monitorFilterData(){
    var moment = require('moment');
    // let Start = moment(new Date()).format("Y-MM-DD 00:00:00");
    let end = moment(new Date()).format("Y-MM-DD");
    let Start = moment(end).add(-7, "days").format("Y-MM-DD");
    let url = `${API_URL}/${router}/filter`;
    return axios.get( url )
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  monitorFilterLimit(limit) {
    // let url = `${API_URL}/${router}/filter?${limit}`;
    this.getFilterMonitoring(limit) 
    .then( data => {
      return data;
  })
  }

  servicesInout(id) {
    let url = `${API_URL}/${router}/inout/${id}`;

    return axios.get( url )
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  servicesSpdy(id) {
    let url = `${API_URL}/${router}/spdy/${id}`;

    return axios.get( url )
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getServicesFiltersInfo(filters) {
    let url = `${API_URL}/${router}/services/filters/info`;

    return axios.get( url, { params: filters } )
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getServices(filters) {
    let url = `${API_URL}/${router}/services`;

    return axios.get(url, { params: filters })
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getServiceByConsecutive(consecutive) {
    let url = `${API_URL}/${router}/service/by/${consecutive}`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  getServiceByUuid(uuid) {
    let url = `${API_URL}/${router}/service/byUuid/${uuid}`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  
}