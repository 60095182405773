<template src="./gmap.component.html"></template>

<script>
import MultiStopPinMap from "@/helpers/MultiStopPinMap.js";
export default {
  name: "GoogleMap",
  props: {
    options: [Object, Array, String],
    from: [String],
    address: [String, Boolean, Number], //texto para Buscar direccion
    kml: [String, Boolean], //Mostar datos del archivo kml
    watcher: [String, Number], //mira los cambios por cada mapa
    market: [String, Object, Array, Boolean], //Funciona como pin principal o como posicion del Domiciliario
    mapHeight: String, //Alto del mapa
    destination: [Array, Object, Boolean], //Uno o mas puntos de destino, falso/undefined si no tiene ninguno. El siguiente es el formato
    //[{"location": [ -74.04575622, 4.68409697 ], "title": "Parada #1: calle 100 # 13", "drag": false, "animation": false, "indice": 0 }]
    origin: [Array, Object, Boolean], //Uno o mas puntos de origen, falso/undefined si no tiene ninguno
    route: [Array, Object],
    coverage: [Array, Object, String, Boolean],
    servicesDelivery:[Array, Object, String, Number],
    themeMap: { // nuevo campo themeMap
    type: Boolean, // tipo de dato
    default: false // valor por defecto
  }
  },
  mixins: [MultiStopPinMap],
  mounted() {
    this.setFormPlace();
  },
  watch: {
    watcher(watch) {
      setTimeout(() => {
        this.mapTest();
      }, 100);
    },
    address(address) {
      // if ( address != undefined ){
      //   var input = document.getElementById('pac-input');
      //   input.value = address;
      //   input.innerHTML = address
      // }
    },
  },
  created() {
    setTimeout(() => {
      this.mapTest();
    }, 500);
  },
  data() {
    return {
      boundCoords: [],
      identyMap: "", //Identificador unico del mapa
      coordenates: {
        latituded: 0,
        longituded: 0,
      },
      place: null,
      coordReturn: {
        lat: "",
        long: "",
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      lightTheme:[
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f0f0f0", // Fondo muy claro
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#333333", // Texto oscuro para buen contraste
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#ffffff", // Fondo de texto blanco
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e0e0e0", // Gris muy claro para áreas administrativas
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#666666", // Gris oscuro para países
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#444444", // Gris medio para localidades
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#333333", // Texto oscuro para puntos de interés
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c5e1a5", // Verde claro para parques
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#2c6b2f", // Verde más oscuro para el texto de los parques
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#ffffff", // Fondo blanco para el texto de parques
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#e0e0e0", // Gris claro para las calles
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#555555", // Gris oscuro para el texto de las calles
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#d0d0d0", // Gris medio para carreteras principales
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#b0b0b0", // Gris más claro para autopistas
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#a0a0a0", // Gris aún más claro para accesos controlados
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#444444", // Gris medio para el texto de calles locales
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#444444", // Gris medio para el texto de tránsito
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#b3cde0", // Azul claro para cuerpos de agua
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#003f5c", // Azul oscuro para el texto de cuerpos de agua
                },
              ],
            },
          ] ,
      darkTheme: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8a8a8a",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#373737",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3c3c3c",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#4e4e4e",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#000000",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
    };
  },
  description: "Autocomplete Example (#164)",
  methods: {
    mapTest() {
      this.boundCoords = [];
      var map,
        src = this.kml,
        globalMarket = [],
        bounds = new google.maps.LatLngBounds(),
        map = new google.maps.Map(document.getElementById(this.identyMap), {
          //Configuraciones basicas del mapa
          //zoom: 39,
          mapTypeControlOptions: {
            mapTypeIds: [],
          },
          streetViewControl: false,
          styles: !this.themeMap ? this.darkTheme : this.lightTheme,
        });

      console.info(MultiStopPinMap.methods.base64PinImage());
      if (this.from == "pdv") {
        //buscador de direcciones en punto de venta
        var input = document.getElementById("pac-input");
        console.log(input)
        var searchBox = new google.maps.places.SearchBox(input);

        map.addListener("bounds_changed", function () {
          searchBox.setBounds(map.getBounds());
        });
        var markers = [];
        var this_ = this;

        // more details for that place.
        searchBox.addListener("places_changed", function () {
          var places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          globalMarket.setMap(null);
          markers.forEach(function (marker) {
            marker.setMap(null);
          });
          markers = [];

          // For each place, get the icon, name and location.
          var bounds = new google.maps.LatLngBounds();

          places.forEach(function (place) {
            if (!place.geometry) {
              return;
            }
            var icon = {
              url: require("@/assets/pinmap.svg"),
              size: new google.maps.Size(38, 38),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25),
            };
            //retornar data
            let places_entropia = searchBox.gm_accessors_.places;
            //console.warn(places_entropia , "searchBox");
            let findMarket = {
              lat: place.geometry.location.lat(),
              long: place.geometry.location.lng(),
              formattedPrediction:
                places_entropia.Wc == undefined
                  ? places_entropia.bd == undefined
                    ? places_entropia.cd == undefined
                      ? places_entropia.dd.formattedPrediction
                      : isNaN(places_entropia.cd.i) == false
                      ? places_entropia.cd.formattedPrediction
                      : places_entropia.cd.i
                    : places_entropia.bd.formattedPrediction
                  : places_entropia.formattedPrediction, //esta mirda esta fallanhdo
            };
            this.formattedPrediction();
            this_.$emit("getCoordinates", findMarket);

            let market_found = new google.maps.Marker({
              map: map,
              icon: icon,
              title: place.name,
              position: place.geometry.location,
              draggable: true,
            });
            markers.push(market_found);
            google.maps.event.addListener(market_found, "dragend", (data) => {
              this_.handlerDragendReturn(data);
            });
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        });
      }
      if (this.kml != "false") {
        //renderizar KML
        var kmlLayer = new google.maps.KmlLayer(src, {
          suppressInfoWindows: true,
          preserveViewport: false,
          map: map,
        });
      }

      this.marketDestination(map); //Destino
      this.marketpdvOnMap(map); //Origen
      this.marketOnMap(map); //marcador

      var flightPlanCoordinates = [];
      if (this.validCoordinates(this.route, 2) == true) {
        //validamos si el prop ruta existe y cumple con las validaciones
        this.route.map((location, index) => {
          if (location != undefined && location[0] != undefined) {
            let originMarket = require("@/assets/pinDelivery.svg");
            let marketloc = new google.maps.LatLng(location[1], location[0]);
            if (this.from != "pdv") {
              if (index == 0 || index == this.route.length - 1) {
                //solo lo muestra al inicio y al final
                var marker = new google.maps.Marker({
                  position: marketloc,
                  map: map,
                  icon: new google.maps.MarkerImage(
                    originMarket,
                    null,
                    null,
                    null,
                    new google.maps.Size(38, 38)
                  ),
                  draggable: false,
                });
              }
            }
            flightPlanCoordinates.push(marketloc);
            this.boundCoords.push(marketloc);
          }
        });

        if (flightPlanCoordinates.length >= 2) {
          //Pintar la linea que marca la ruta
          var flightPath = new google.maps.Polyline({
            map: map,
            path: flightPlanCoordinates,
            strokeColor: "#4bfb7d",
            strokeOpacity: 1.0,
            strokeWeight: 5,
          });
        }
      }

      this.boundCoords.forEach((item) => {
        bounds.extend(item);
      });

      map.fitBounds(bounds);
      if (this.from == "pdv" && this.route == undefined) {
        //&& this.route.length == 0
        setTimeout(() => {
          map.setZoom(16);
        }, 0);
      }
    },
    marketDestination(ref_map) {
      //Puntos de destino.  Vista de monitoreo
      if (
        this.destination != false &&
        this.destination != undefined &&
        this.destination.length >= 1
      ) {
        // && this.validCoordinates( this.route, 2 ) != true
        let destination_market = require("@/assets/pinmap.svg");
        let defaultMarker = new google.maps.MarkerImage(
          destination_market,
          null,
          null,
          null,
          new google.maps.Size(38, 38)
        );

        this.destination.map((item, index) => {
          if (item.location != undefined) {
            let indPlusOne;
            if(!this.servicesDelivery){
              indPlusOne = index + 1;
            }else{
              indPlusOne= this.servicesDelivery[index].asignedServices
            }
            let iconStepNumber = new google.maps.MarkerImage(
              MultiStopPinMap.methods.base64PinImage(indPlusOne),
              null,
              null,
              null,
              new google.maps.Size(42, 42)
            );
            let iconFinalStep = new google.maps.MarkerImage(
              MultiStopPinMap.methods.base64PinFinalImage(indPlusOne),
              null,
              null,
              null,
              new google.maps.Size(45, 45)
            );

            //

            let destinationSpdy = {
              lat: Number(item.location[1]),
              lng: Number(item.location[0]),
            };
            var marker = new google.maps.Marker({
              position: destinationSpdy,
              map: ref_map,
              icon:
                this.destination.length == 1
                  ? defaultMarker
                  : indPlusOne == this.destination.length &&
                    this.options != undefined &&
                    this.options.returned === false
                  ? iconFinalStep
                  : iconStepNumber,
              draggable: item.drag == undefined ? false : item.drag,
            });
            if (item.rid != undefined || item.title != undefined) {
              let body_ =
                item.content == undefined
                  ? ""
                  : `<p class="body-popup-gmap">${item.content}</p>`;
              let content = `
                      <div class="map-tooltip">
                          <h4>${item.title}</h4>
                          ${body_}
                      </div>`;
              var infowindow = new google.maps.InfoWindow({
                content: content,
              });

              marker.addListener("click", function () {
                infowindow.open(ref_map, marker);
              });
            }
            if (item.animation != undefined && item.animation == true) {
              marker.setAnimation(google.maps.Animation.BOUNCE);
            }
            if (item.drag != undefined && item.drag == true) {
              let indice = 210;
              google.maps.event.addListener(marker, "dragend", (data) => {
                this.handlerDragendReturn(data, item.indice);
              });
            }
            this.boundCoords.push(destinationSpdy);
          }
        });
      }
    },
    marketpdvOnMap(ref_map) {
      //pin punto de venta
      if (
        this.origin != false &&
        this.origin != undefined &&
        this.origin.length >= 1
      ) {
        //&& this.validCoordinates( this.route, 2 ) != true
        let originMarket = require("@/assets/pinPDV.svg");
        this.origin.map((item) => {
          if (item.location != undefined) {
            let originSpdy = { lat: item.location[1], lng: item.location[0] };
            var marker = new google.maps.Marker({
              position: originSpdy,
              map: ref_map,
              icon: new google.maps.MarkerImage(
                originMarket,
                null,
                null,
                null,
                new google.maps.Size(38, 38)
              ),
              draggable: false,
            });

            let content = `
                          <div class="map-tooltip">
                              <h3>${item.name}</h3>
                              <ul class="body-map-tooltip">
                                <li><b>Dirección: </b> ${item.address} </li>
                              </ul>
                          </div>`;

            var infowindow = new google.maps.InfoWindow({
              content: content,
            });
            marker.addListener("click", function () {
              infowindow.open(ref_map, marker);
            });
            this.boundCoords.push(originSpdy);
          }
        });
      }
    },
    marketOnMap(ref_map) {
      if (
        this.market != "false" &&
        this.market != undefined &&
        this.market.length == 2 &&
        this.validCoordinates(this.route, 2) != true
      ) {
        //Posicionar marcador principal domiciliario o pin
        let spdy_market =
          this.from == "monitoring"
            ? require("@/assets/pinDelivery.svg")
            : require("@/assets/pinmap.svg");
        let mainMarket = { lat: this.market[1], lng: this.market[0] };
        let dragger =
          this.destination == false && this.origin == false ? true : false;
        dragger = this.from == "monitoring" ? false : dragger;
        var marker = new google.maps.Marker({
          position: mainMarket,
          map: ref_map,
          icon: new google.maps.MarkerImage(
            spdy_market,
            null,
            null,
            null,
            new google.maps.Size(38, 38)
          ),
          draggable: dragger,
        });

        google.maps.event.addListener(marker, "dragend", (data) => {
          this.handlerDragendReturn(data);
        });
        //globalMarket = marker;
        this.boundCoords.push(mainMarket);
      }
    },
    mapStyle() {
      return { height: this.mapHeight != undefined ? this.mapHeight : "135px" };
    },
    setFormPlace() {
      this.coordenates = this.currentCoordenates;
      this.identyMap = this.watcher == undefined ? "map" : "map" + this.watcher;
    },
    mapData() {
      return {
        lat: this.coordenates.latitude,
        lng: this.coordenates.longitude,
      };
    },
    handlerDragendReturn(data_, indice) {
      //Al finalizar el evento de arrastra se emite un evento con las coordenadas
      this.coordReturn.lat = data_.latLng.lat();
      this.coordReturn.long = data_.latLng.lng();
      if (indice != undefined && indice != null) {
        this.coordReturn.indice = indice;
      }
      this.$emit("getCoordinates", this.coordReturn);
    },
    setPlace(place) {
      this.place = place;

      this.coordenates.latitude = this.place.geometry.location.lat();
      this.coordenates.longitude = this.place.geometry.location.lng();
    },
    validCoordinates(prop_, max) {
      return prop_ != undefined && prop_ != null && prop_.length >= max;
    },
  },
};
</script>
<style>
#map {
  height: 100%;
  overflow: hidden;
  float: left;
  border: thin solid var(--spdy-secondary-lighten-color);
}
.map-tooltip {
  color: var(--spdy-secondary-lighten-color);
  cursor: pointer;
}
.map-tooltip h4 {
  color: var(--spdy-secondary-lighten-color);
}
.body-map-tooltip {
  margin-top: 5px !important;
}
.map-tooltip h3 {
  margin: 0px;
  color: var(--spdy-secondary-color);
}
.map-tooltip h3 span {
  font-weight: 400 !important;
}
.map-tooltip ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.gm-ui-hover-effect {
  top: 0px !important;
  right: 0px !important;
}
.pac-container.pac-logo {
  z-index: 10000 !important;
  background: var(--spdy-secondary-lighten-color) none repeat scroll 0% 0% !important;
}
.pac-container.pac-logo .pac-item:hover,
.pac-container.pac-logo .pac-item.pac-item-selected {
  background-color: var(--spdy-primary-color) !important;
}
.pac-container.pac-logo .pac-item:hover .pac-item-query,
.pac-container.pac-logo .pac-item.pac-item-selected .pac-item-query {
  color: var(--spdy-secondary-color) !important;
}
.pac-container.pac-logo .pac-item {
  border-top: 1px solid var(--spdy-secondary-color) !important;
}
.pac-container.pac-logo .pac-item .pac-item-query {
  color: #fff !important;
}
p.body-popup-gmap {
  margin: 0px !important;
  margin-bottom: 10px !important;
}
</style>
