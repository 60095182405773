import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;
const header = process.env.VUE_APP_HEADER

let router = "user";
let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

export class Users {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  deteleBeforeToken(){
      console.log("deteleBeforeToken")
      delete axios.defaults.headers.common['x-access-token'];
  }

  /**
   * Login user
   * @param {string} username
   * @param {string} password
   */
  Login(username, password) {
    let url = `${API_URL}/${router}/login`

    return axios.post(url,  {
        username: username,
        password: password
      },config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET all users
   * @param {object} params filters, limit, page size
   */
  GetUsers(params) {
    let uri = `${API_URL}/${router}`;
    let url =  params != undefined ? `${uri}/${params}` : uri; 

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET all users
   * @param {object} params filters, limit, page size
   */
  GetUsersFilter(params) {
    let url = `${API_URL}/${router}filter?${params}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET user by rid
   * @param {string} rid
   */
  GetUser(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
      // console.log(error.response)
      error.response.data.error = error.response.data.message
      return error.response.data
    })
}

  /**
   * PUT user by rid update
   * @param {string} rid
   */
  PutUser(rid, data) {
    let url = `${API_URL}/${router}/${rid}`
    //console.log(url)
    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST user create
   * @param {string} rid
   */
  PostUser(data) {
    let url = `${API_URL}/${router}`;

      console.warn(url, "url");
      console.warn(data, "data");

    return axios.post(url, data, config)
      .then(response =>  response.data )
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE User remove
   * @param {string} rid
   */
  DeleteUser(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  ifExistUser(username) {
    let url = `${API_URL}/userexist/username/${username}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  ifExistPhone(phone) {
    let url = `${API_URL}/userexist/mobile/${phone}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}