import axios from 'axios';
import store from '../store';

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;
let config = {
  headers: {  
    'Authorization':'Basic ZDMzMGFhNDktYWQ5OS00YWY3LTgxNmMtZDliNGQ2MzY0OGEx',
    'Content-Type':'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With',
  }
};

let router = "notify";

export class onesignal {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token;
  }

   sendNotificationOneSignal( notify ){
    let url = `${API_URL}/${router}`;

      return axios.post(url, notify, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
      })
   }

} 