import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "rol";

export class Rols {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all Rols
   * @param {object} params filters, limit, page size
   */
  GetRols(rid) {
    //let url = `${API_URL}/rol`
    let url = `${API_URL}/${router}/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET rol by rid
   * @param {string} rid
   */
  GetRol(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT rol by rid update
  * @param {string} rid
  */
  PutRol(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    delete data.readOnly

    if (!data.permission) {
      data.permission = {}
    }

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Rol create
   * @param {string} rid
   */
  PostRol(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Rol create
   * @param {string} rid
   */
  PostPermisionsRol(rid, data) {
    let url = `${API_URL}/${router}/${rid}/permission`;

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  

  /**
   * DELETE rol remove
   * @param {string} rid
   */
  DeleteRol(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}

