import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'spdy',
  storage: localStorage
})

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    loginData:{},
    holidays:[],
    novelties:{},
    deliveryStatus:{},
    lodelaCookie:[],
    logAssignedTurns:[],
    meshView:'',
    isLogin: false,
    authData: {},
    modalUser:false,
    methodUser: 'create',
    comerceUser:{},
    dataMeshModel:{},
    toggle:true,
    userPermissions:[],
    savingMesh: false,
    commerces: [],
    pointSales: [],
    cities: [],
    selectedCommerce: {},
    selectedPointsale: {},
    pointSalesByCommerce: [],
    dashboardData: {
      commerces: [],
      pointSales: [],
      cities: []
    },filter:[],
    filterMonitorig:[]
    ,
    chartData: {
      salesChart: [],
      moneyVTimeDay: [],
      ordersVTimeDay: [],
      moneyVTimeWeek: [],
      ordersVTimeWeek: [],
      moneyVTimeMonth: [],
      ordersVTimeMonth: []
    },
    notificationsData: [],
  },
  mutations: {
    SET_LOGIN_DATA (state, data) {
      state.loginData = data
    },
    SET_USER_DATA(state, payload){
      state.user = payload;
    },
    SET_HOLIDAYS_LIST(state, payload){
      state.holidays = payload;
    },
    SET_NOVELTY_NOTIFY(state, payload){
      state.novelties = payload;
    },
    DELIVERY_STATUS_CHANGE(state, payload){
      state.deliveryStatus = payload;
    },
    SET_ASSIGNED_TURNS(state, payload){//store de los turnos asignados y cambiados
      state.logAssignedTurns.push(payload.turns);
    },
    CLEAR_ASSIGNED_TURNS(state, payload){
      state.logAssignedTurns = payload.turns;
    },
    toggleSavingMesh(state, payload){
      state.savingMesh = payload.data;
    },
    SET_MODEL_VIEW( state, playload ){
      state.meshView = playload;
    },
    SET_NOTIFICATION(state, playload){
      state.notifications = playload;
    },
    setNotificationsData (state, playload) {
      state.notificationsData = playload;
    },  
    toggleMenu(state, payload){
      state.toggle = payload.data;
    },
    //Guardar el array de comercio
    postComerceUser(state, payload){
      this.comerceUser = payload;
    },
    setModelMesh(state, payload){
        this.dataMeshModel = payload;
    },
    changeModal(){
        this.state.modalUser = this.state.modalUser == true ? false : true;
    },
    closeModel(){
      this.state.modalUser = false;
    },
    changeMethodUser(state, payload){
      this.state.methodUser = payload.value;
    },
    login(state, data) {
      state.isLogin = data.isLogin
      if (state.isLogin) {
        state.authData  = data.authData;
       
      } else {
        this.state.authData = {}
      }
    },
    setCommerces (state, data) {
      state.commerces = data
    },
    setPointsales (state, data) {
       state.pointSales = data
    },
    setCities (state, data) {
      state.cities = data
    },
    setSelectedCommerce (state, data) {
       state.selectedCommerce = data
    },
    setSelectedPointsale (state, data) {
       state.selectedPointsale = data
    },
    setPointSalesByCommerce (state, data) {
        state.pointSalesByCommerce = [ ...state.pointSalesByCommerce, ...data ]
    },
    clearPointSalesByCommerce (state) {
        state.pointSalesByCommerce = []
    },
    setDashboardData (state, data) {
      state.dashboardData = data
    },
    setChartData (state, data) {
      switch (data[0].type) {
        case 'moneyVTimeDay':
          state.chartData.moneyVTimeDay = [ ...state.chartData.moneyVTimeDay, ...data ]
          break
        case 'ordersVTimeDay':
          state.chartData.ordersVTimeDay = [ ...state.chartData.ordersVTimeDay, ...data ]
          break
        case 'moneyVTimeWeek':
          state.chartData.moneyVTimeWeek = [ ...state.chartData.moneyVTimeWeek, ...data ]
          break
        case 'ordersVTimeWeek':
          state.chartData.ordersVTimeWeek = [ ...state.chartData.ordersVTimeWeek, ...data ]
          break
        case 'moneyVTimeMonth':
          state.chartData.moneyVTimeMonth = [ ...state.chartData.moneyVTimeMonth, ...data ]
          break
        case 'ordersVTimeMonth':
          state.chartData.ordersVTimeMonth = [ ...state.chartData.ordersVTimeMonth, ...data ]
          break
      }
    },
     clearChartData (state, data) {
      if (data) {
        state.chartData = {
          salesChart: [],
          moneyVTimeDay: [],
          ordersVTimeDay: [],
          moneyVTimeWeek: [],
          ordersVTimeWeek: [],
          moneyVTimeMonth: [],
          ordersVTimeMonth: []
        }
      }
    }
  },
  actions: {
    SET_COMPLETE_LOGIN({commit}, data){
      commit("setLoginData", data);  
    },
    SET_COMMERCES: ({ commit }, data) => {
      commit('setCommerces', data)
    },
    SET_POINTSALES: ({ commit }, data) => {
      commit('setPointsales', data)
    },
     SET_CITIES: ({ commit }, data) => {
      commit('setCities', data)
    },
    SET_SELECTED_COMMERCE: ({ commit }, data) => {
      commit('setSelectedCommerce', data)
    },
    SET_SELECTED_POINTSALE: ({ commit }, data) => {
      commit('setSelectedPointsale', data)
    },
    SET_POINTSALE_BY_COMMERCE: ({ commit }, data) => {
      commit('setPointSalesByCommerce', data)
    },
    CLEAR_POINTSALES_BY_COMMERCE: ({ commit }, data) => {
      commit('clearPointSalesByCommerce', data)
    },
    SET_DASHBOARD_DATA: ({ commit }, data) => {
      commit('setDashboardData', data)
    },
    SET_CHART_DATA: ({ commit }, data) => {
      commit('setChartData', data)
    },
     CLEAR_CHART_DATA: ({ commit }, data) => {
      commit('clearChartData', data)
    },
    SET_NOTIFICATIONS_DATA: ({ commit }, data) => {
      commit('setNotificationsData', data)
    },
  },
  getters: {
     watchNotifications(state){
       console.log(state.notifications, "state")
     },
     stateToggle(state){
        return state.methodUser;
     },
     ahsnnsh: (state, getters) =>{
       return state.lodelaCookie
     },
     commerces: state => state.commerces,
     pointSales: state => state.pointSales,
     cities: state => state.cities,
     selectedCommerce: state => state.selectedCommerce,
     selectedPointsale: state => state.selectedPointsale,
     pointSalesByCommerce: state => state.pointSalesByCommerce,
     dashboardData: state => state.dashboardData,
     chartData: state => state.chartData,
  }
})
