const moment = require("moment");
import { Message } from "element-ui";
import { Country } from "@/services/Country";
import store from "../store";
import loginMiddle from '@/helpers/loginMiddle.js'
var module = require("colombia-holidays");

const ModelCountry = new Country();

export const myMixin = {
  data() {
    return {
      // moment:moment,
      data_response: [],
      view_permissions: [0, 0, 0, 0]
    };
  },
  mixins:[loginMiddle],
  methods: {
    formMilitaryTime(hour){
      return moment(hour, 'HH:mm').format('hh:mm a');
    },
    isEmptyModel(model){//return true si el modelo (objeto o array) esta vacio, es null o undefined
      if (model == undefined || model == null){
        return true;
      }else if ( model != undefined && model != null ){
        let typeData  = typeof model;
        if ( typeData == "object"){
          return this.isEmpty(model);
        }
        else if ( typeData == "array" ){
          return model.length == 0 ? true : false;
        }
      }
    },
    isEmpty(obj) {
      //Valida si un key existe dentro de un objeto
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    getHolidaysInYear() {
      let format_holidays = [];
      let date = moment(new Date()).format("YYYY");
      var holidays = module.getColombiaHolidaysByYear(date);
      holidays.map(holiday => {
        format_holidays.push({
          code: holiday.celebration,
          holiday: holiday.celebrationDay,
          editable: false
        });
      });
      return format_holidays;
    },
    currency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    dayTurnedIndex(dayCurrent) {
      //Toma un dia de la semana y retorna el indice correspondiente
      let indexModel;
      switch (dayCurrent) {
        case "lunes":
          indexModel = 0;
          break;
        case "martes":
          indexModel = 1;
          break;
        case "miércoles":
          indexModel = 2;
          break;
        case "miercoles":
          indexModel = 2;
          break;
        case "jueves":
          indexModel = 3;
          break;
        case "viernes":
          indexModel = 4;
          break;
        case "sábado":
          indexModel = 5;
          break;
        case "sabado":
          indexModel = 5;
          break;
        case "domingo":
          indexModel = 6;
          break;
      }
      return indexModel;
    },
    getArrayLocationCoordinates(location) {
      //Convierte los keys de las locaciones
      if (typeof location == "object") {
        if (location.lng != undefined && location.lat != undefined) {
          return [location.lng, location.lat];
        }

        if (location.latitude != undefined && location.longitude != undefined) {
          return [location.longitude, location.latitude];
        }
      }
    },









    // getCookie(cname) {
    //   var name = cname + "=";
    //   var dCookie = document.cookie
    //   var ca = dCookie.split(";");
    //   for (var i = 0; i < ca.length; i++) {
    //     var c = ca[i];
    //     while (c.charAt(0) == " ") {
    //       c = c.substring(1);
    //     }
    //     if (c.indexOf(name) == 0) {
    //       return c.substring(name.length, c.length);
    //     }
    //   }
    //   return "";
    // },
    // checkPermissionOnZero(permissions) {
    //   return (
    //     permissions[0] == 0 &&
    //     permissions[1] == 0 &&
    //     permissions[2] == 0 &&
    //     permissions[3] == 0
    //   );
    // },
    // checkViewMonitoring() {
    //   let permisos = JSON.parse(this.getCookie("permissions")) || [];
    //   if (permisos == undefined) {
    //     return "error";
    //   }
    //   if (permisos.roles[0] != undefined && permisos.roles[0].admin == true) {
    //     return "Admin";
    //   }

    //   if (permisos.roles.length == 0) {
    //     return "si roles";
    //   }
    //   let permision =
    //     permisos.roles[0].permission.length == 0
    //       ? "sin definir"
    //       : permisos.roles[0].permission[0].url.componente;
    //   return permision;
    // },
    // checkStorePermissions(viewName) {
    //   let cookies_permissions = this.getCookie("permissions");

    //   //console.log(cookies_permissions)
    //   let view_permissions = [0, 0, 0, 0],
    //     storePermission = JSON.parse(cookies_permissions) || [],
    //     roles_,
    //     isAdmin =
    //       storePermission.roles.length >= 1
    //         ? storePermission.roles[0].admin
    //         : false;

    //   if (storePermission != undefined) {
    //     if (isAdmin == true) {
    //       view_permissions = [1, 1, 1, 1];
    //     } else if (isAdmin == false) {
    //       roles_ = storePermission.roles || [];
    //       if (roles_.length >= 1) {
    //         roles_.map(rol => {
    //           rol.permission.map(permiso => {
    //             for (let entry of permiso.url.componente["url-service"]) {
    //               if (entry[viewName] != undefined) {
    //                 view_permissions = entry[viewName];
    //               }
    //             }
    //           });
    //         });
    //       }
    //     }
    //   }

    //   //console.warn(view_permissions,'view_permissions');
    //   return view_permissions;
    // },










    getTimerSeconds(seconds) {
      //Convierte los segundos a tiempo valido 00
      if (seconds < 0) {
        throw new RangeError(
          "Número de segundos invalidos, asegurate de que todos los datos sean correctos"
        );
      }

      //console.log(seconds)
      let d = Math.floor(seconds / 86400);
      let h = Math.floor((seconds % 86400) / 3600);
      let m = Math.floor(((seconds % 86400) % 3600) / 60);
      let s = ((seconds % 86400) % 3600) % 60;

      m = m < 10 ? "0" + m : m; //Anteponiendo un 0 a los minutos si son menos de 10
      s = s < 10 ? "0" + s : s; //Anteponiendo un 0 a los segundos si son menos de 10

      if (d > 0) {
        return ` Varios Días `; //${moment.utc().hours(h).minutes(m).seconds().format("HH:mm:ss")}
      } else {
        return moment
          .utc()
          .hours(h)
          .minutes(m)
          .seconds(s)
          .format("HH:mm:ss");
      }
    },
    getTimer(mins) {
      //Convierte los minutos a tiempo valido 00
      if (mins < 0) {
        throw new RangeError(
          "Valid input should be greater than or equal to 0 and less than 1440."
        );
      }
      let hrs;
      let h = (mins / 60) | 0,
        m = mins % 60 | 0,
        d = (mins / (24 * 60)) | 0,
        s = m / 60;
      h = h % 24;

      if (d > 0) {
        return ` Varios Días `
      } else {
        let format = "";
        if (h >= 1) {
          format = "hh:mm:00";
        } else if (h == 0) {
          format = "00:mm:00";
        }

        return moment
          .utc()
          .hours(h)
          .minutes(m)
          .seconds(40)
          .format(format);
      }
    },
    fromTimeToMinutes(time) {
      //Convertir tiempo a minutos
      let time_ = moment.duration(time).asMinutes();
      return time_;
    },
    diffBetweenDates(time) {
      var fecha1 = moment(time);
      var fecha2 = moment(new Date());
      return Math.abs(fecha2.diff(fecha1, "seconds"));
    },

    calculateTime(start, end) {
      let startTime = moment(start, "HH:mm:ss a");
      let endTime = moment(end, "HH:mm:ss a");

      return startTime.diff(endTime);
    },

    startCronometer(timeInitial, timerCurrentH, timerCurrentM, timerCurrentS) {
      //Iniciar cronometro
      if (
        timeInitial != undefined &&
        timerCurrentH != undefined &&
        timerCurrentM != undefined &&
        timerCurrentS != undefined
      ) {
        let seconds_ = parseInt(timerCurrentS);
        let timeActual = new Date();
        let acumularTime = timeActual - timeInitial;
        let acumularTime2 = new Date();
        acumularTime2.setTime(acumularTime);
        acumularTime2.setSeconds(parseInt(timerCurrentS)); //colocamos los segundos validos
        acumularTime2.setMinutes(parseInt(timerCurrentM));

        let cc = Math.round(acumularTime2.getMilliseconds() / 10);
        let ss = acumularTime2.getSeconds(); //modulo
        let mm = acumularTime2.getMinutes();
        let hh = 0;

        if (cc < 10) {
          cc = "0" + cc;
        }
        if (ss < 10) {
          ss = "0" + ss;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }

        if (timerCurrentH != undefined && timerCurrentH != "") {
          hh = parseInt(timerCurrentH);
        }

        let time_ =
          hh <= 9
            ? "0" + hh + ":" + mm + ":" + ss
            : hh + ":" + mm + ":" + ss;
        return time_;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    timeStampRegister() {
      let d = new Date();
      let n = d.getTime();
      return n;
    },
    checkSpecialsCharacters(param) {
      return /\W|_/g.test(param);
    },
    nmbValid(e, objl, txt) {
      let calcnmb = typeof e == "number" ? e.toString() : e;
      let valueToEval = calcnmb.length,
        maxValue = objl[1],
        minValue = objl[0],
        toReturn;

      if (valueToEval <= maxValue && valueToEval >= minValue) {
        toReturn = true;
      } else if (valueToEval > maxValue) {
        this.setAlert(
          "warning",
          "la longitud debe ser menor a " +
            (maxValue + 1) +
            "" +
            (txt == undefined ? "" : "en " + txt)
        );
        toReturn = false;
      } else if (valueToEval < minValue) {
        this.setAlert(
          "warning",
          "la longitud debe ser mayor a " +
            minValue +
            " " +
            (txt == undefined ? "" : "en " + txt)
        );
        toReturn = false;
      }
      return toReturn;
    },
    weekOfYear(date) {
      let m = moment(new Date(date));
      return m.week();
    },
    addDateDay(date, day) {
      let m = moment(new Date(date));
      //console.info(day);
      let date_ = m.add("days", parseInt(day)).format("YYYY-MM-DD");
      return date_;
    },
    endDayOfWeekend(date_) {
      let m = moment(new Date(date_ + " 23:59:59"));
      return m.add("days", 6);
    },
    removeOneOfWeekend(date_) {
      let m = moment(new Date(date_));
      return m.subtract(0, "days");
    },
    settingDate: function(dates) {
      let dates_ = [];
      dates.map(date => {
        dates_.push(moment(date).format("YYYY-MM-DD"));
      });
      return dates_;
    },
    settingDate_format: function(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    settingTime: function(time) {
      let time_ = [];
      time.map(tm => {
        time_.push(moment(tm).format("HH:mm:ss"));
      });
      return time_;
    },
    validateTimer(strg) {
      //verifica si existe (:) dos puntos en los caracteres; si no valida si los caracteres coinciden con un tiempo si es asi retorna true si no false
      if (strg.search(/:/i) == -1) {
        if (strg.length < 6) {
          return false;
        } else if (strg.length == 6) {
          let hours = strg.substring(0, 2);
          let mins = strg.substring(2, 4);
          let seconds = strg.substring(4, 6);
          let time_final_format = hours + ":" + mins + ":" + seconds;
          return time_final_format;
        }
      } else if (strg.search(/:/i) != -1) {
        return true;
      }
    },
    validateIfHHMMSSisValid(response, alertIfWrong) {
      let final_date = "";
      let pattern = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/i;
      if (response == false) {
        myMixin.methods.setAlert("error", alertIfWrong);
        return "error";
      } else if (response != false) {
        let valid = response == true ? response : response.match(pattern);
        final_date = response == true ? response : response.match(pattern);
        if (valid == null) {
          myMixin.methods.setAlert("error", alertIfWrong);
          return "error";
        }
        return final_date;
      }
    },

    setAlert(type, text) {
      Message({
        type: type,
        showClose: true,
        message: text
      });
    },

    findCountryByCity(cityRid, model) {
      let found = model.find(item => {
        return item.rid == cityRid;
      });
      return found.country;
    },
    checkIsNumber(number) {
      return isNaN(number); //return false if it's a number
    },
    checkEmailValid(email) {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    },
    /*Prueba para centralizar todos los componentes de servicios */
    handlerResponse(data) {
      if (data.err) {
        this.setAlert("error", data.err);
      } else {
        return data;
      }
    },
    /*:::::::::Get all countries::::::::::*/
    getAllCountries() {
      return ModelCountry.GetCountries()
        .then(data => {
          console.log(this.handlerResponse(data));
          return this.handlerResponse(data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    listPermisions() {
      //lista de permisos a utilizar en la app
      let modelsPermisions = [
        //Si se modifica el orden de este objeto, verificar el indice en el archivo side.bar.vue
        { url: "commerce", viewname: "Comercios" },
        { url: "pointSale", viewname: "PDV" },
        { url: "deliveryMan", viewname: "Domiciliarios" },
        { url: "rol", viewname: "Roles" },
        { url: "user", viewname: "Usuarios" },
        { url: "zone", viewname: "Zonas" },
        { url: "TypeCommerce", viewname: "Tipo de comercio" },
        { url: "typeDocument", viewname: "Tipo de documento" },
        { url: "city", viewname: "Ciudad" },
        { url: "countries", viewname: "Paises" },
        { url: "typeInvoice", viewname: "Tipo de factura" },
        { url: "typeAccountBank", viewname: "Tipos de bancos" },
        { url: "IvaRegime", viewname: "Impuestos" },
        { url: "banks", viewname: "Bancos" },
        { url: "typeVehicle", viewname: "Tipos de vehiculo" },
        { url: "monitor-delivery", viewname: "Mon. Domiciliarios" },
        { url: "monitor-dataphone", viewname: "Moni. Datáfonos" },
        { url: "monitor", viewname: "Mon. servicios" },
        { url: "mesh", viewname: "Mallas" },
        { url: "paymentMethod", viewname: "Métodos de pago" },
        { url: "holidays", viewname: "Festivos" }
      ];

      return modelsPermisions;
    },
    Initialpermissions() {
      let modelsPermisions = this.listPermisions();

      let permissions = {
        inDB: false,
        name: "",
        module: "commerce",
        url: {
          componente: {
            "url-service": []
          }
        }
      };

      modelsPermisions.map(item => {
        permissions.url.componente["url-service"].push({
          [item.url]: [0, 0, 0, 0]
        });
      });
      console.log(permissions, "permisos en initialpermissions");

      return permissions;
    },
    returnPermissions() {
      let views = this.listPermisions();
      return views;
    },
    blockMenusToSideBar() {
      let views = this.listPermisions();
      let coicidences = [];
      views.map(permision => {       
        let perms = loginMiddle.methods.checkStorePermissions(permision.url);
        coicidences.push({
          name: permision.url,
          permissions: perms
        });
      });
      return coicidences;
    }
  }
};

export default myMixin;
