import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "permission";

export class Permissions {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET 
   * @param {string} rid
   */
  GetAllPermisions(rid) {
    let url =  rid != undefined ? `${API_URL}/${router}/${rid}` : `${API_URL}/${router}`;
    console.warn("Los permisos");

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT 
  * @param {string} rid
  */
  PutPermisions(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    delete data.readOnly

    if (!data.permission) {
      data.permission = {}
    }

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST 
   * @param {string} rid
   */
  PostPermisions(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE rol remove
   * @param {string} rid
   */
  DeletePermisions(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}

