import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

export class TypeDocuments {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

    /**
     * GET all Document Types
     * @param {object} params filters, limit, page size
     */
    GetTypeDocuments(params) {
      let url = `${API_URL}/t-document`

      return axios.get(url)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
    }

  /**
   * GET Document Type by rid
   * @param {string} rid
   */
  GetTypeDocument(rid) {
    let url = `${API_URL}/t-document/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT Document Type by rid update
  * @param {string} rid
  */
  PutTypeDocument(rid, data) {
    let url = `${API_URL}/t-document/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Document Type create
   * @param {string} rid
   */
  PostTypeDocument(data) {
    let url = `${API_URL}/t-document`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE Document Type remove
   * @param {string} rid
   */
  DeleteTypeDocument(rid) {
    let url = `${API_URL}/t-document/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }



}



