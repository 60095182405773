import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

export class Banks {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  accountsBanksDelivery(rid){
    let url = `${API_URL}/account-bank/${rid}?fields=bank,bankNumber,bankName,bankTypeAccount`;

      return axios.get(url)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }

  DeleteAccountBank(rid) {
    let url = `${API_URL}/account-bank/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  postComerceBanks(data){
    let url = `${API_URL}/account-bank/`;

    return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
  }

  putComerceBanks(rid, data){
    let url = `${API_URL}/account-bank/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /**
   * GET all banks
   * @param {object} params filters, limit, page size
   */
  GetBanks(params) {
    let url = `${API_URL}/bank`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET Bank by rid
   * @param {string} rid
   */
  GetBank(rid) {
    let url = `${API_URL}/bank/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT Bank by rid update
  * @param {string} rid
  */
  PutBank(rid, data) {
    let url = `${API_URL}/bank/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Bank create
   * @param {string} rid
   */
  PostBank(data) {
    let url = `${API_URL}/bank`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })  
  }

  /**
   * DELETE Bank remove
   * @param {string} rid
   */
  DeleteBank(rid) {
    let url = `${API_URL}/bank/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}

