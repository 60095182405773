import store from "../store";

export const loginMiddle = {
  data() {
    return {
      data_response: [],
      view_permissions: [0, 0, 0, 0],
    };
  },
  methods: {
    getCookie(cname) {
      var name = cname + "=";
      var dCookie = document.cookie;
      var ca = dCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    checkPermissionOnZero(permissions) {
      return (
        permissions[0] == 0 &&
        permissions[1] == 0 &&
        permissions[2] == 0 &&
        permissions[3] == 0
      );
    },
    checkViewMonitoring() {
      // key: url-service, monitoring, mesh_deep_permission
      let permisos = store.state.loginData || [];
      if (permisos == undefined) {
        return "error";
      }
      if (permisos.roles[0] != undefined && permisos.roles[0].admin == true) {
        return "Admin";
      }
      if (permisos.roles.length == 0) {
        return "si roles";
      }
      let permision =
        permisos.roles[0].permission.length == 0
          ? "sin definir"
          : permisos.roles[0].permission[0].url.componente;
      return permision;
    },
    checkStorePermissions(view_name) {
      // verifica los permisos de acuerdo al nombre de la vista
      let logindata = store.state.loginData,
        defaul_permissions = [0, 0, 0, 0],
        roles,
        isAdmin =
          store.state.loginData != undefined &&
          (store.state.loginData.roles && store.state.loginData.roles.length) == 1
            ? store.state.loginData.roles[0].admin
            : false;
      if (logindata != undefined) {
        if (isAdmin == true) {
          defaul_permissions = [1, 1, 1, 1];
        } else if (isAdmin == false) {
          roles = logindata.roles || [];

          if (view_name != undefined) {
            roles.map((rol) => {
              rol.permission.map((viewPermission) => {
                for (let view of viewPermission.url.componente["url-service"]) {
                  if (view[view_name] != undefined) {
                    defaul_permissions = view[view_name];
                  }
                }
              });
            });
          }
        }
      }
      return defaul_permissions;
    },
  },
};
export default loginMiddle;
