import axios from "axios";
import store from "../store";

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;;

let router = "paymentMethod";

export class PaymentMethods {
  constructor() {
    axios.defaults.headers.common["x-access-token"] =
      store.state.authData.token;
  }


  /**
   * GET all Document Types
   * @param {object} params filters, limit, page size
   */
  getSpecificPayment(rid) {
    let url =
      rid != undefined
        ? `${API_URL}/get${router}/${encodeURIComponent(rid) }`
        : `${API_URL}/${router}/`;
    console.log(rid,"rid");
    console.log(url,"rid");
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  GetPaymentMethod(rid) {
    let url =
      rid != undefined
        ? `${API_URL}/${router}/${encodeURIComponent(rid) }`
        : `${API_URL}/${router}/`;
    console.log(rid,"rid");
    console.log(url,"rid");
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  /**
   * PUT Document Type by rid update
   * @param {string} rid
   */
  PutPaymentMethod(rid, data) {
    let url = `${API_URL}/${router}/${rid}`;

    return axios
      .put(url, data)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  /**
   * POST Document Type create
   * @param {string} rid
   */
  PostPaymentMethod(data) {
    let url = `${API_URL}/${router}`;

    return axios
      .post(url, data)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  /**
   * DELETE Document Type remove
   * @param {string} rid
   */
  DeletePaymentMethod(rid) {
    let url = `${API_URL}/${router}/${rid}`;

    return axios
      .delete(url)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
