<template>
  <div class="main">
    <Report v-if="$store.state.isLogin" ></Report>
  </div>
</template>

<script>
import Report from '@/components/Dashboard/Report.vue'
export default {
  name: 'Dashboard',
  components: {
    Report,
  }
}
</script>
<style scope="sccs">
  .main{
    height: 100%;
  }
</style>