import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "mash";

export class Mash {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all meshs
   * @param {object} params filters, limit, page size
   */
  GetMash(params) {
    let url = params != undefined ? `${API_URL}/${router}/${params}` : `${API_URL}/${router}`;
    
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET all meshs
   * @param {object} params filters, limit, page size
   */
  GetMashFilter(params) {
    let url = `${API_URL}/mashfilter?fields=day,zone,dateStart,dateEnd&zone=${params}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  filterMeshWithAnyParams(params){
    let url = `${API_URL}/mashfilter?${params}`;
    
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  GetMashFilterGlobal(rid){
    let url = `${API_URL}/mashfilter?city=${rid}`;
    
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Bank create
   * @param {string} rid
   */
  PostMash(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
  * PUT Bank by rid update
  * @param {string} rid
  */
  PutMash(rid, data) {
    let url = `${API_URL}/${router}/${rid}`;

    console.log(url)

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  
  /**
   * DELETE Bank remove
   * @param {string} rid
   */
  DeleteMash(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}

