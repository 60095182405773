import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let router = "vehicle";

export class Vehicles {
 
    constructor() {
      axios.defaults.headers.common['x-access-token'] = store.state.authData.token
    }

  /**
   * GET all PointSale
   * @param {object} params filters, limit, page size
   */
  GetVehicles(rid) {
    let url = rid == undefined ? `${API_URL}/${router}` : `${API_URL}/${router}/${rid}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /**
   * POST PointSale create
   * @param {string} rid
   */
  PostVehicles(data) {
    let url = `${API_URL}/${router}`;
    
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
  * PUT PointSale by rid update
  * @param {string} rid
  */
  PutVehicles(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  
  /**
   * DELETE PointSale remove
   * @param {string} rid
   */
  DeleteVehicles(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}

