import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;


let config = {
  headers: {  
    'Content-Type': 'multipart/form-data'
  }
};

let router = "delivery";

export class  Upload {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all commerces
   * @param {object} params filters, limit, page size
   */
  
   postDocumentsDelivery(rid, formData, document){
    let url = `${API_URL}/deliverymanupload/${rid}/${document}`;

    console.log(url, "la uri");
      return axios.post(url, formData, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

   uploadZoneKml(rid, formData, document){
    let url = `${API_URL}/zoneupload/${rid}/${document}`;

    console.log(url, "la uri");
      return axios.post(url, formData, config)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

  

}