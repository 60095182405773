import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "invoice-delivery-man";

export class invoiceDelivery {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all commerces
   * @param {object} params filters, limit, page size
   */

  getinvoiceDelivery(model){
    let url = `${API_URL}/${router}`;

    console.log(url, "la url");
    return axios.get(url, { params: model })
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
   }
  
   postInvoiceDeliveriers(data){
    let url = `${API_URL}/${router}`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

   PutRateCommerce(data) {
    let url = `${API_URL}/commerce-rate`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

   DeleteRateCommerce(data) {
    let url = `${API_URL}/commerce-rate`;

    return axios.delete(url, {data:data })
          .then(response => response.data)
          .catch((error) => {
            return error.response.data
          })
  }

   postRateCommerce(data){//Insertar tarifas en comercios
    let url = `${API_URL}/commerce-rate`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

  

   postPointsaleCommerce(rid, data){
    let url = `${API_URL}/${router}/${rid}/pointsale`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

   postContactCommerce(rid, data){
    let url = `${API_URL}/${router}/${rid}/contact`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

  GetCommerces(id) {
    let url = id != undefined ? `${API_URL}/${router}/${id}` : `${API_URL}/commerce`;
    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * get Commerce by rid
   * @param {string} rid
   */
  GetCommerce( param = '' ) {
    let url_ = param == '' ? 'commerce': `commerce/${param}`;
    let url = `${API_URL}/${url_}`;
    
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        //console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
   * put Commerce by rid update
   * @param {string} rid
   */
  PutCommerce(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * post Commerce create
   * @param {string} rid
   */
  PostCommerce(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * delete Commerce remove
   * @param {string} rid
   */
  DeleteCommerce(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}