import myMixin from "@/helpers/mixins.js";
import { Country } from "@/services/Country";
import { Commerces } from "@/services/Commerce";
import { typeCommerces } from "@/services/typeCommerces";
import { TypeInvoices } from "@/services/TypeInvoice";
import { Banks } from "@/services/Bank";
import { BankTypeAcc } from "@/services/BankTypeAcc";
import { Cities } from "@/services/City";
import { IvaRegimes } from "@/services/IvaRegime";
import { Contacts } from "@/services/Contact";
import { PointSales } from "@/services/pointSales";
import { Schedule } from "@/services/Schedules";
import { Dataphones } from "@/services/Dataphone";
import { Deliveryman } from "@/services/Deliveryman";
import { TypeDocuments } from "@/services/TypeDocument";
import { pension } from "@/services/pension";
import { Eps } from "@/services/Eps";
import { Vehicles } from "@/services/Vehicles";
import { TypeVehicle } from "@/services/TypeVehicle";
import { Zones } from "@/services/Zones";
import { Rols } from "@/services/Rol";
import { Dashboard } from "@/services/Dashboard";
import { Users } from "@/services/User";
import { Upload } from "@/services/Upload";
import { Mash } from "@/services/Mash";
import { Mesh } from "@/services/Mesh";
import { Monitor } from "@/services/monitor";
import { InvoiceCommerces } from "@/services/CommerceInvoice";
import { invoiceDelivery } from "@/services/deliveryInvoices";
import { Permissions } from "@/services/Permisions";
import { onesignal } from "@/services/oneSignal";
import { PaymentMethods } from "@/services/paymentMethod";
import { Directions } from "@/services/Directions";
import { Holidays } from "@/services/Holidays";

import CONSTANTS from "@/constants/Strings";
import moment from "moment";

const ModelHolidays = new Holidays();
const ModelDirections = new Directions();
const ModelOnesignal = new onesignal();
const ModelpaymentMethod = new PaymentMethods();
const ModelPermissions = new Permissions();
const ModelInvoiceDelivery = new invoiceDelivery();
const ModelInvoiceCommerces = new InvoiceCommerces();
const ModelMonitor = new Monitor();
const ModelMesh = new Mesh();
const ModelMash = new Mash();
const modelUpload = new Upload();
const ModelUser = new Users();
const ModelRols = new Rols();
const ModelZones = new Zones();
const ModelTypeVehicle = new TypeVehicle();
const ModelVehicles = new Vehicles();
const ModelEps = new Eps();
const ModelPension = new pension();
const ModeltypeDocuments = new TypeDocuments();
const Modeldeliveryman = new Deliveryman();
const ModelDataphones = new Dataphones();
const ModelSchedule = new Schedule();
const ModelPointSales = new PointSales();
const ModelContacts = new Contacts();
const ModelIvaRegimes = new IvaRegimes();
const ModelCity = new Cities();
const ModelBankTypeAcc = new BankTypeAcc();
const ModelBanks = new Banks();
const ModelTypeInvoices = new TypeInvoices();
const ModeltypeCommerces = new typeCommerces();
const ModelCommerce = new Commerces();
const ModelCountry = new Country();
const ModelDashboard = new Dashboard();

export const centralServices = {
  data() {
    return {
      data_response: [],
    };
  },
  mixins: [myMixin],
  methods: {
    /*Prueba para centralizar todos los componentes de servicios */
    handlerResponse(data) {
      // if (!data) {
      //   myMixin.methods.setAlert("error", data);
      // } else {
      return data;
      // }
    },
    /*:::::::::Get all countries::::::::::*/
    getSpecificPayment(rid){
      return ModelpaymentMethod.getSpecificPayment(rid)
      .then((data) => {
        return this.handlerResponse(data);
      })
      .catch((error) => {});
    },
    getPaymentMethods() {
      //Metodos de pago
      // let id =  encodeURIComponent(rid);
      return ModelpaymentMethod.GetPaymentMethod()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    createPaymentMethod(payment) {
      //Crear metodos de pago
      return ModelpaymentMethod.PostPaymentMethod(payment).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    editPaymentMethod(payment) {
      //Crear metodos de pago
      let id = encodeURIComponent(payment.rid);
      return ModelpaymentMethod.PutPaymentMethod(id, payment).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    deletePaymentMethod(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelpaymentMethod.DeletePaymentMethod(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:::::::Comercios
    getAllUserComerces(data) {
      //GET
      return ModelCommerce.GetUserCommerces(data)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    getAllComerces(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";
      return ModelCommerce.GetCommerces(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteCommerce(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelCommerce.DeleteCommerce(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    updateCommerce(rid, model) {
      //PUT
      let id = encodeURIComponent(rid);

      return ModelCommerce.PutCommerce(id, model).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    createCommerce(data) {
      //CREATE
      return ModelCommerce.PostCommerce(data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    getRatesCommerce(rid) {
      //GET
      let id = encodeURIComponent(rid);

      return ModelCommerce.getRatesCommerce(id).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    createRatesCommerce(data) {
      //CREATE
      return ModelCommerce.postRateCommerce(data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    deleteRatesCommerce(data) {
      //DELETE
      return ModelCommerce.DeleteRateCommerce(data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    upadteRatesCommerce(data) {
      //DELETE
      //let id = encodeURIComponent(data.rid);

      return ModelCommerce.PutRateCommerce(data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },

    //bancos asociados a comercios
    createCommerceBanks(rid, data) {
      let id = encodeURIComponent(rid);
      return ModelCommerce.postAccountCommerce(id, data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    //Contactos Asociados a comercios
    createCommerceContacts(rid, data) {
      let id = encodeURIComponent(rid);

      return ModelCommerce.postContactCommerce(id, data)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    //Puntos de venta asociados al comercio
    commercePointSale(rid, data) {
      let id = encodeURIComponent(rid);

      return ModelCommerce.postPointsaleCommerce(id, data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    getCommercesPointSales(rid) {
      let id = encodeURIComponent(rid);
      return ModelCommerce.GetCommercesPointSale(id).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },

    /*:::::::invoice comerce */
    getInvoicesCommerce(data_filter) {
      //get Invoices
      return ModelInvoiceCommerces.GetInvoiceCommerce(data_filter).then(
        (data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        }
      );
    },
    createInvoicesCommerce(data) {
      //get Invoices
      return ModelInvoiceCommerces.postInvoiceCommerce(data).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },
    getInvoicesPayment(data_filter) {
      //get payment
      return ModelInvoiceCommerces.GetPaymentCommerce(data_filter).then(
        (data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        }
      );
    },

    //:::Bancos
    getAllBanks() {
      //GET
      return ModelBanks.GetBanks()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteBank(rid) {
      //DELETE
      let id = encodeURIComponent(rid);
      return ModelBanks.DeleteBank(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createBank(bank) {
      //CREATE
      return ModelBanks.PostBank(bank)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    editBank(bank) {
      //UPDATE
      let id = encodeURIComponent(bank.rid);

      return ModelBanks.PutBank(id, bank)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    updateBankCommerce(bank) {
      let id = encodeURIComponent(bank.rid);

      return ModelBanks.putComerceBanks(id, bank).then((data) => {
        let response = this.handlerResponse(data);
        if (response.message) {
          myMixin.methods.setAlert("success", response.message);
        }
        return response;
      });
    },


    //:::::TypeBankCommerce
    getAllAccountBank() {
      //GET
      return ModelBankTypeAcc.GetBanksTypeAcc()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteAllAccountBank(rid) {
      //DELETE
      let id = encodeURIComponent(rid);
      return ModelBankTypeAcc.DeleteBankTypeAcc(id)
        .then((data) => {
            myMixin.methods.setAlert("success", data.message);
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    createTypeAccount(accountType) {
      //CREATE
      return ModelBankTypeAcc.PostBankTypeAcc(accountType)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {
          this.$message({
            type: "error",
            showClose: true,
            message: "Ha ocurrido un error.",
          });
        });
    },
    updateTypeAccount(accountType) {
      //UPDATE
      let id = encodeURIComponent(accountType.rid);

      return ModelBankTypeAcc.PutBankTypeAcc(id, accountType)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //::::Tipos de comercios
    getAllTypeComerces() {
      //GET
      return ModeltypeCommerces.GetTypeCommerces()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteTypeCommerce(rid) {
      //DELETE
      let id = encodeURIComponent(rid);
      return ModeltypeCommerces.DeleteTypeCommerces(id)
        .then((data) => {
          myMixin.methods.setAlert("success", data.message);
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    createTypeCommerce(tcommerce) {
      //CREATE
      return ModeltypeCommerces.postTypeCommerces(tcommerce)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    editTypeCommerce(tcommerce) {
      //UPDATE
      let id = encodeURIComponent(tcommerce.rid);
      return ModeltypeCommerces.PutTypeCommerces(id, tcommerce)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:::::::Tipo de facturación
    getTypesInvoices() {
      //GET
      return ModelTypeInvoices.GetTypeInvoices()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteTypeInvoice(rid) {
      //DELETE
      let id = encodeURIComponent(rid);
      return ModelTypeInvoices.DeleteTypeInvoice(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createTypeInvoices(Tinvoice) {
      //CREATE
      return ModelTypeInvoices.PostTypeInvoice(Tinvoice)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    updateTypeInvoice(invoice) {
      let id = encodeURIComponent(invoice.rid);

      return ModelTypeInvoices.PutTypeInvoice(id, invoice)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:::::Ciudades
    getAllCities(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelCity.GetCities(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteCity(rid) {
      let id = encodeURIComponent(rid);
      return ModelCity.DeleteCity(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createCity(city) {
      return ModelCity.PostCity(city)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    editCity(city) {
      let id = encodeURIComponent(city.rid);
      return ModelCity.PutCity(id, city)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:::::Paises
    getAllCountries() {
      return ModelCountry.GetCountries()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteCountry(rid) {
      let id = encodeURIComponent(rid);
      return ModelCountry.DeleteCountry(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createCountry(country) {
      return ModelCountry.PostCountry(country)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    updateCountry(country) {
      let id = encodeURIComponent(country.rid);
      return ModelCountry.PutCountry(id, country)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    getFilterCityByCountries(rid) {
      let id = encodeURIComponent(rid);

      return ModelCity.filterCityByCountry(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //::::: Tipos de comercio
    GetAllIvaRegimes() {
      //GET
      return ModelIvaRegimes.GetIvaRegimes()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteIvaRegimen(rid) {
      //DELETE
      let id = encodeURIComponent(rid);
      return ModelIvaRegimes.DeleteIvaRegime(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createIvaRegimen(regimIVA) {
      //CREATE
      return ModelIvaRegimes.PostIvaRegime(regimIVA)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    editIvaRegimen(regimIVA) {
      let id = encodeURIComponent(regimIVA.rid);

      return ModelIvaRegimes.PutIvaRegime(id, regimIVA)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:::Contactos
    createContacts(data) {
      return ModelContacts.PostContact(data)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    updateContacts(rid, data) {
      let id = encodeURIComponent(rid);
      return ModelContacts.PutContact(id, data)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteContacts(rid) {
      let id = encodeURIComponent(rid);

      return ModelContacts.DeleteContact(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //::::Puntos de ventas
    getAllPointSale(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelPointSales.GetPointSales(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },

    
    updatePointSales(rid, data) {
      //UPDATE
      let id = encodeURIComponent(rid);

      //console.error(data, "data");

      return ModelPointSales.PutPointSales(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deletePointSales(rid) {
      let id = encodeURIComponent(rid);
      return ModelPointSales.DeletePointSales(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    createContactPDV(rid, data) {
      let id = encodeURIComponent(rid);

      return ModelPointSales.PostContactPointSales(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    //Schedule To PDV
    createSchedulePDV(rid, data) {
      let id = encodeURIComponent(rid);

      return ModelPointSales.PostSchedulesPointSales(data, id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    //actualizar Horario PDV
    updateSchedulePDV(rid, data) {
      let id = encodeURIComponent(rid);
      return ModelSchedule.PutSchedule(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    //Datafonos a puntos de venta
    createPointSalesDataPhones(rid, item) {
      let id = encodeURIComponent(rid);

      return ModelPointSales.PostDatafonosPointSales(id, item).then((data) => {
        return this.handlerResponse(data);
      });
    },

    //::::Horarios
    deleteSchedule(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelSchedule.DeleteSchedule(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //::Datafonos
    updateDataphone(rid, data) {
      let id = encodeURIComponent(rid);
      return ModelDataphones.PutDataphone(data, id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deleteDataphone(rid) {
      //DELETE
      let id = encodeURIComponent(rid);
      return ModelDataphones.DeleteDataPhone(id).then((data) => {
        return this.handlerResponse(data);
      });
    },

    //:::::Domiciliarios
    getAllDeliveryMan(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";
      return Modeldeliveryman.GetDeliverymen(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deleteDeliveryMan(id) {
      //DELETE
      let id_ = encodeURIComponent(id);

      return Modeldeliveryman.DeleteDeliveryman(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    updateDeliveryMan(model, rid) {
      //UPDATE
      let id = encodeURIComponent(rid);
      return Modeldeliveryman.PutDeliveryman(model, id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    createDeliveryMan(model) {
      //UPDATE
      return Modeldeliveryman.PostDeliveryman(model).then((data) => {
        return this.handlerResponse(data);
      });
    },
    validUsername(model){
      return Modeldeliveryman.validDeliveryman(model).then((data) => {
        return this.handlerResponse(data);
      });
    },
    //::::: Tarifas Domiciliario

    getDeliveryManR() {
      return Modeldeliveryman.GetDeliveryManRates().then((data) => {
        return this.handlerResponse(data);
      });
    },

    postDeliveryManR(model) {
      return Modeldeliveryman.PostDeliveryManRate(model).then((data) => {
        myMixin.methods.setAlert("success", data.message);
        return this.handlerResponse(data);
      });
    },

    putDeliveryManR(model) {
      return Modeldeliveryman.PutDeliveryManRate(model).then((data) => {
        myMixin.methods.setAlert("success", data.message);
        return this.handlerResponse(data);
      });
    },

    deleteDeliverymanR(rid) {
      return Modeldeliveryman.DeleteDeliverymanRate(rid).then((data) => {
        myMixin.methods.setAlert("success", data.message);
        return this.handlerResponse(data);
      });
    },

    //Vehiculos Asociados a domiciliarios
    createVehicleDeliveryMan(rid, data) {
      let id = encodeURIComponent(rid);

      return Modeldeliveryman.PostDeliverymanVehicle(data, id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    GetDeliverymenFilter(rid) {
      //Filtra los Domiciliarios por ciudad
      let id = encodeURIComponent(rid);
      return Modeldeliveryman.GetDeliverymenFilter(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getDeliverymenFilterOk(filter, rid) {
      let model = "",
        id = encodeURIComponent(rid);
      switch (filter) {
        case "zone":
          model = `?zone=${id}`;
          break;

        case "city":
          model = `?city=${id}`;
          break;
      }
      return Modeldeliveryman.GetDeliverymenFilterOk(model).then((data) => {
        return this.handlerResponse(data);
      });
    },

    /* Facturas en domiciliarios */
    GetDeliveryManInvoices(model) {
      //GET
      return ModelInvoiceDelivery.getinvoiceDelivery(model).then((data) => {
        return this.handlerResponse(data);
      });
    },
    createInvoiceDeliveriers(model) {
      //POST
      return ModelInvoiceDelivery.postInvoiceDeliveriers(model).then((data) => {
        return this.handlerResponse(data);
      });
    },

    //:::Type Documents
    getAllDocumentsTypes(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";
      return ModeltypeDocuments.GetTypeDocuments(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deleteDocumentType(id) {
      //DELETE
      let id_ = encodeURIComponent(id);
      return ModeltypeDocuments.DeleteTypeDocument(id_)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createDocumentType(document) {
      //POST
      return ModeltypeDocuments.PostTypeDocument(document)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    updateDocumentType(document) {
      //UPDATE
      let id = encodeURIComponent(document.rid);

      return ModeltypeDocuments.PutTypeDocument(id, document)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:::EPS
    getAllEPS(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";
      return ModelEps.GetEps(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //:::PENSION
    getAllPENSION(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";
      return ModelPension.GetPension(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //::VEHICULOS
    getAllVehicles() {
      //GET
    },
    updateVehicle(rid, data) {
      //UPDATE
      let id = encodeURIComponent(rid);

      return ModelVehicles.PutVehicles(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deleteVehicle(rid) {
      return ModelVehicles.DeleteVehicles(rid).then((data) => {
        return this.handlerResponse(data);
      });
    },

    //Tipos de vehiculos
    getAllVehiclesTypes() {
      //GET
      return ModelTypeVehicle.GetTypeVehicle()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteVehiclesTypes(rid) {
      //GET
      let id = encodeURIComponent(rid);
      return ModelTypeVehicle.DeleteTypeVehicle(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createVehicleType(tVehicle) {
      return ModelTypeVehicle.PostTypeVehicle(tVehicle)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    updateVehicleType(tVehicle) {
      let id = encodeURIComponent(tVehicle.rid);

      return ModelTypeVehicle.PutTypeVehicle(tVehicle, id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },

    //:ZONES
    getAllZones(rid) {
      let id_ = rid != undefined ? encodeURIComponent(rid) : "";
      // ;
      return ModelZones.GetZones(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getDeliveryMenFromPDV(rid){
      console.log(rid)
      return ModelMonitor.getDeliveryMenFromPDV(rid).then((data) => {
        return this.handlerResponse(data);
      });
    },
    saveZonesUpdate(rid, data) {
      //PUT
      let id = encodeURIComponent(rid);

      return ModelZones.PutZone(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    saveZones(data) { 
      //CREATE
      return ModelZones.PostZone(data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getFilterZone(by, rid) {
      let id = encodeURIComponent(rid);
      let filter_ = "";
      if (by == "city") {
        filter_ = `city=${id}`;
      }
      return ModelZones.filterZone(filter_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getZonesByDelivery(rid) {
      //GET ZONE BY Delivery
      let id = encodeURIComponent(rid);
      return ModelZones.filterDeliveryZone(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getInvalidZones(rid) {
      //GET ZONE BY Delivery
      let id = encodeURIComponent(rid);
      return ModelZones.getInvalidZones(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getZonesById(rid) {
      //GET ZONE BY CITY
      let id = encodeURIComponent(rid);
      return ModelZones.filterCityZone(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    updateZone(rid, model) {
      //UPDATE
      let id = encodeURIComponent(rid);
      return ModelZones.PutZone(id, model)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //:ROLES
    getAllRoles(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelRols.GetRols(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteRol(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelRols.DeleteRol(id)
        .then((data) => {
          myMixin.methods.setAlert("success", data.message);
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    updateRol(rol) {
      //UPDATE
      let id = encodeURIComponent(rol.rid);

      return ModelRols.PutRol(id, rol)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    createRol(rol) {
      //CREATE
      return ModelRols.PostRol(rol)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    createPermissionsToRol(rid, data) {
      let id = encodeURIComponent(rid);

      return ModelRols.PostPermisionsRol(id, data)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    //:USUARIOS
    getAllUsers(id) {
      //  GET ALL USERS
      let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelUser.GetUsers(id_)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteCurrentUser(rid) {
      //DELETE ALL USERS
      let id = encodeURIComponent(rid);
      return ModelUser.DeleteUser(id)
        .then((data) => {
          let response = this.handlerResponse(data);
          if (response.message) {
            myMixin.methods.setAlert("success", response.message);
          }
          return response;
        })
        .catch((error) => {});
    },
    createUser(user) {
      //CREATE
      return ModelUser.PostUser(user).then((data) => {
        //console.info(data, "response")
        return this.handlerResponse(data);
      });
    },
    updateUser(user) {
      //UPDATE
      let id = encodeURIComponent(user.rid);
      return ModelUser.PutUser(id, user).then((data) => {
        return this.handlerResponse(data);
      });
    },

    validateUsername(username) {
      return ModelUser.ifExistUser(username).then((data) => {
        return this.handlerResponse(data);
      })
    },

    validatePhone(phone) {
      return ModelUser.ifExistPhone(phone).then((data) => {
        return this.handlerResponse(data);
      })
    },

    //Subir archivos al domiciliario
    uploadDeliveryManFiles(rid, formData, document) {
      let id = encodeURIComponent(rid);

      return modelUpload
        .postDocumentsDelivery(id, formData, document)
        .then((data) => {
          return this.handlerResponse(data);
        });
    },
    //Subir archivos al domiciliario
    uploadKmlToZones(rid, formData, document) {
      let id = encodeURIComponent(rid);

      return modelUpload.uploadZoneKml(id, formData, document).then((data) => {
        return this.handlerResponse(data);
      });
    },

    //MALLAS ASIGNADAS
    getMash(rid) {
      //GET
      let id_ = rid != undefined ? encodeURIComponent(rid) : "";

      return ModelMash.GetMash(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deleteMash(rid) {
      let id = encodeURIComponent(rid);

      return ModelMash.DeleteMash(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    assignMeshToModel(model) {
      //ASIGNAR UNA MALLA A UN MODELO
      return ModelMash.PostMash(model).then((data) => {
        return this.handlerResponse(data);
      });
    },
    editAssignedMesh(rid, data) {
      let id = encodeURIComponent(rid);

      //console.warn( JSON.parse(data) );
      return ModelMash.PutMash(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getMashFilter(rid) {
      //Obtener los datos
      let id = encodeURIComponent(rid);

      return ModelMash.GetMashFilter(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    GetMashCompleteFilter(filter) {
      ////FILTER MESH WITH ANY PARAMS

      return ModelMash.filterMeshWithAnyParams(filter).then((data) => {
        return this.handlerResponse(data);
      });
    },

    //MODELOS DE MALLAS
    getAllModelMeshes() {
      //GET
      return ModelMesh.GetDeliveryMesh()
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    editModelMesh(rid, model) {
      //PUT
      let id = encodeURIComponent(rid);

      return ModelMesh.PutDeliveryMesh(id, model)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    createModelMesh(model) {
      //CREATE
      return ModelMesh.PostDeliveryMesh(model)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    deleteModelMesh(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelMesh.DeleteDeliveryMash(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },
    /*MONITOREO*/
    getService(rid) {
      //Filtra los servicios por fecha
      let id = encodeURIComponent(rid);
      return ModelMonitor.getService(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    quoteServiceMonitor(dt_) {
      //Filtra los servicios por fecha
      return ModelMonitor.quote(dt_).then((data) => {
        return this.handlerResponse(data);
      });
    },


    getTrackingDelivery(rid) {
      //Obtener los tracking del domiciliario
      let id = encodeURIComponent(rid);

      return ModelMonitor.trackingDelivery(id).then((data) => {
        return this.handlerResponse(data);
      });
    },


    filterServiceMonitor(filter) {
      //Filtra los servicios por fecha
      return ModelMonitor.monitorServiceFilter(filter).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getmonitorFilterData() {
      return ModelMonitor.monitorFilterData().then((data) => {
        return this.handlerResponse(data);
      });
    },

    getmonitorFilterLimit(limit) {
      return ModelMonitor.monitorFilterLimit(limit)
        .then( data => {
          return this.handlerResponse(data)
        });
    },
    requestService(data){//
        return ModelMonitor.requestService(data)
          .then( data => {
              return this.handlerResponse(data);
          })
    },
    _putService(data) {
      //
      return ModelMonitor.cancelService(data).then((data) => {
        return this.handlerResponse(data);
      });
    },

    _cancelService(data) {
      //
      return ModelMonitor.cancelService(data).then((data) => {
        return this.handlerResponse(data);
      });
    },

    getAllServicesFromDelivery(id) {
      let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelMonitor.getAllServicesFromDelivery(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getAllMonitoring(id) {
      let id_ = id != undefined ? encodeURIComponent(id) : "";
      // getMonitor
      return ModelMonitor.getMonitorDelivery(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },
    getAllMonitoringDelivery(filter) {
      //Monitoreo de domiciliario
      // let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelMonitor.getMonitorDelivery(filter).then((data) => {
        return this.handlerResponse(data);
      });
    },
    FilterMonitoringDelivery(id_, key_) {

      
      return ModelMonitor.getFilterMonitorDelivery(id_).then((data) => {
        return this.handlerResponse(data);
      });
    },

    setServiceToDeliveryman(data) {
      //ASIGNAR SERVICIO A DOMICILIARIO
      return ModelMonitor.assignServiceToDelivery(data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    filterMonitoring(filter) {
      return ModelMonitor.getFilterMonitoring(filter).then((data) => {
        return this.handlerResponse(data);
      });
    },
    routeDeliveryMan(consecutive) {
      //Obtener la ruta del domicliario
      return ModelMonitor.getRouteDeliveryMan(consecutive).then((data) => {
        return this.handlerResponse(data);
      });
    },
    updateRoute(data, consecutive) {
      //Filtra los servicios por fecha
      //let id = encodeURIComponent(rid);
      return ModelMonitor.updateCoords(data, consecutive).then((data) => {
        return this.handlerResponse(data);
      });
    },

    getServicesInout(id) {
      return ModelMonitor.servicesInout(id).then((data) => {
        return this.handlerResponse(data);
      });
    },

    getServicesSpdy(id) {
      return ModelMonitor.servicesSpdy(id).then((data) => {
        return this.handlerResponse(data);
      });
    },

    /*PERMISOS */
    getAllPermisions(id) {
      //GET
      let id_ = id != undefined ? encodeURIComponent(id) : "";

      return ModelPermissions.GetAllPermisions(id).then((data) => {
        return this.handlerResponse(data);
      });
    },

    createPermissions(data) {
      //POST
      return ModelPermissions.PostPermisions(data).then((data) => {
        return this.handlerResponse(data);
      });
    },

    UpdatePermissions(rid, data) {
      //PUT
      let id = encodeURIComponent(rid);
      return ModelPermissions.PutPermisions(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },

    deletePermisions(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelPermissions.DeletePermisions(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    /*Holidays */
    GetHolidays(rid) {
      //Obtener los festivos
      let id = rid != undefined ? encodeURIComponent(rid) : "";

      return ModelHolidays.GetHoliday(id).then((data) => {
        return this.handlerResponse(data);
      });
    },
    createHolidays(data) {
      //Agregar los fstivos
      return ModelHolidays.PostHoliday(data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    UpdateHoliday(data) {
      //PUT
      let id = encodeURIComponent(data.rid);
      return ModelHolidays.PutHoliday(id, data).then((data) => {
        return this.handlerResponse(data);
      });
    },
    deleteHoliday(rid) {
      //DELETE
      let id = encodeURIComponent(rid);

      return ModelHolidays.DeleteHoliday(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    /*One signal send Notify */
    onesignal(param) {
      //ModelOnesignal
      return ModelMonitor.sendNotificationOneSignal(param)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    requestDeliveryCoords(rid) {
      if (rid === undefined || rid === null) {
        throw new Error("El valor de 'rid' es inválido.");
      }
      let id = encodeURIComponent(rid);
      return ModelMonitor.getCoordinatesDeliveryMan(id)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    getCurrentDate() {
      const today = new Date();
      const dateArray = today.toLocaleString().split("/");
      const year = dateArray[2].split(" ")[0];
      const month = dateArray[1];
      const day = dateArray[0];
      const time = dateArray[2].split(" ")[1];
      const data = {
        today: today,
        month: today.getMonth(),
        year: today.getFullYear(),
        monthString: CONSTANTS.MONTHS[today.getMonth()],
        day: today.getDate(),
        dayString: CONSTANTS.DAYS[today.getDay()],
        formattedDate: `${year}-${month}-${day} ${time}`,
        startDate: `${year}-${month}-1 00:00:00`,
        endDate: `${year}-${month}-${day} 23:59:59`,
        quarterStart: `${moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD")} 00:00:00`,
        quarterEnd: `${moment().format("YYYY-MM-DD")} 23:59:59`,
        dayStart: `${year}-${month}-${day} 00:00:00`,
        dayEnd: `${year}-${month}-${day} 23:59:59`,
        weekStart: `${moment()
          .subtract(1, "weeks")
          .format("YYYY-MM-DD")} 00:00:00`,
        weekEnd: `${moment().format("YYYY-MM-DD")} 23:59:59`,
        yearStart: `${year}-01-01 00:00:00`,
        yearEnd: `${year}-${month}-${day} 23:59:59`,
      };
      return data;
    },
    convertStringDate(dates) {
      const formattedDates = dates.map((date) => {
        let splitedDate = date.split("-");
        return `${CONSTANTS.SHORT_MONTHS[parseInt(splitedDate[1] - 1)]} ${
          splitedDate[0]
        }`;
      });
      return formattedDates;
    },
    extractTimeFromDate(dates) {
      const formattedTimes = dates.map((date) => {
        let splitedDate = date.split(" ");
        return this.toStandardTime(splitedDate[1]);
      });
      return formattedTimes;
    },
    toStandardTime(militaryTime) {
      militaryTime = militaryTime.split(":");
      if (parseInt(militaryTime[0]) > 12) {
        return militaryTime[0] - 12 + "PM";
      } else if (parseInt(militaryTime[0]) < 12) {
        return militaryTime[0] + "AM";
      } else {
        return militaryTime[0] + "PM";
      }
    },

    getUnique(arr, comp) {
      const unique = arr
        .map((e) => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e]);

      return unique;
    },
    getDashBoardData(component, range, dashboardData) {
      let response;
      switch (component) {
        case "salesChart":
          response = this.getChartData("salesChart", range, dashboardData);
          break;
        case "timelineChart":
          response = this.getChartData("timelineChart", range, dashboardData);
          break;
        case "lineChart":
          response = this.getChartData("lineChart", range, dashboardData);
          break;
      }
      return response;
    },
    async getChartData(component, range, dashboardData) {
      let response;
      const data = {
        dateStart: range.startDate,
        dateEnd: range.endDate,
        commerces: dashboardData.commerces,
        cities: ["#41:1"],
      };

      switch (component) {
        case "salesChart":
          response = await ModelDashboard.postPoints(data);
          break;
        case "timelineChart":
          response = await ModelDashboard.postServices(data);
          break;
        case "lineChart":
          response = await ModelDashboard.postServicesTime(data);
          break;
      }
      return response;
    },
    requestDirections(param) {
      //Consultar las direcciones
      return ModelDirections.directions_consult(param)
        .then((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {});
    },

    reportResults(model) {
      return ModelDashboard.getDataReports(model)
       .then((data) => {
         return this.handlerResponse(data);
       })
       .catch((error) => {return error})
    },

    changeStatus(model, rid){
      let id = encodeURIComponent(rid);
      
      return Modeldeliveryman.PutStatusDeliveryman(model, id)
        .then ((data) => {
          return this.handlerResponse(data);
        })
        .catch((error) => {return error})
    }
    
  },
};



export default centralServices;