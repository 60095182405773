import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With',
    'Content-Type':'application/json'
  }
};

let router = "holiday";

export class Holidays {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  GetHoliday(rid) {
    let url =  rid == "" || rid == undefined ? `${API_URL}/${router}` : `${API_URL}/${router}/${rid}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

   PutHoliday(rid, data){
    let url = `${API_URL}/${router}/${rid}`;

      return axios.put(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

   PostHoliday(data){
    let url = `${API_URL}/${router}`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

  DeleteHoliday(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}