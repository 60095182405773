import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "t-invoice";

export class TypeInvoices {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all Type Invoices
   * @param {object} params filters, limit, page size
   */
  GetTypeInvoices(params) {
    let url = `${API_URL}/${router}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET Type Invoice by rid
   * @param {string} rid
   */
  GetTypeInvoice(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT Type Invoice by rid update
  * @param {string} rid
  */
  PutTypeInvoice(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Type Invoice create
   * @param {string} rid
   */
  PostTypeInvoice(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE Type Invoice remove
   * @param {string} rid
   */
  DeleteTypeInvoice(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}