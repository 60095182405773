import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let router = 'report'
let config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With'
  }
}

export class Dashboard {
  constructor () {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  postTotals (data) {
    
    let resDemo =   {
      services: {
          servicesDelivered: 1233,
            daysOfMonth: 22,
            percent: 356
        },
      costs: {
          costMonth: 5520000,
            averageOfService: 4900,
            percent: 22
        },
      timeService: {
          averageTime: 24,
            waitingMinuts: 12,
            percent: 2
        },
      earnings: {
          services: 39600000,
            averageTickets: 32200,
            percent: 28
        }
    }
    console.log('✍', data)
    let endpoint = 'totals'
    let url = `${API_URL}/${router}/${endpoint}`
    let url2 = "https://api.spdy.com.co/v1/report/totals"
    return axios.post(url2, data, config)
      .then(response => resDemo)
      .catch((error) => {
        return error.response.data
      })

  }

  postPoints (data) {
    let endpoint = 'point-sales'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  postNovelties (data) {
    let endpoint = 'novelties'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  postPayments (data) {
    let endpoint = '/payment-methods'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  postServices (data) {
    let endpoint = 'services'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  postServicesTime (data) {
    let endpoint = 'services-time'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  
  postDeliveryTimes (data) {
    let endpoint = 'delivery-time'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  postUsers (data) {
    let endpoint = 'new-users'
    let url = `${API_URL}/${router}/${endpoint}`
    return axios.post(url, data, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  getDataReports(model) {
    let endpoint = 'commerce';
    let url = `${API_URL}/${router}/${endpoint}/${model}`
    console.log(url, "model del service getDataReports")
    return axios.get(url, model, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}
