<template src="./nav.bar.component.html"></template>

<script>
import centralServices from "@/helpers/services.js";
import Sidebar from "@/components/layout/SideBar/side.bar.vue";
import CONSTANTS from "@/constants/Strings";
import { mapActions, mapGetters } from "vuex";

import { Cities } from "@/services/City";
import { PointSales } from "@/services/pointSales";
import { Commerces } from "@/services/Commerce";

const ModelCities = new Cities();
const ModelPointSales = new PointSales();
const ModelCommerce = new Commerces();

export default {
  mixins: [centralServices],
  components: {
    Sidebar
  },
  computed: {
    ...mapGetters([
      "commerces",
      "pointSales",
      "cities",
      "selectedCommerce",
      "selectedPointsale",
      "pointSalesByCommerce"
    ])
  },
  watch: {
    $route(to, from) {
      if (this.$route.fullPath.indexOf("dashboard") > -1) {
        this.showAdditionalFilters = true;
      } else {
        this.showAdditionalFilters = false;
      }
    }
  },
  created() {
    this.months = CONSTANTS.MONTHS.map((month, i) => {
      let number = i + 1;
      let data = {
        name: month,
        number: ("0" + number).slice(-2)
      };
      return data;
    });
    if (this.$store.state.isLogin == true) {
      this.initDataStore();
    }
  },
  mounted() {
    //
    // this.$bus.$on('initialFillTriggered', (newMonth) => {
    //   if ( number != undefined ){
    //     this.selectedMonth = {name: newMonth, number}
    //     this.selectedMonth.name = ('0' + this.getMonthNumber(newMonth)).slice(-2)
    //   }
    // })
  },
  data() {
    return {
      user: {},
      rols: [],
      pointSalesData: {
        commerceRid: null,
        data: []
      },
      showAdditionalFilters: false,
      months: [],
      selectedMonth: {
        name: "",
        number: 0
      },
      selectedSetting: "",
      settingData: {
        options: [
          {
            name: "Últimos 30 días",
            compareDays: "Últimos 30 días",
            value: "22 ene - 22 feb"
          },
          {
            name: "Este Mes",
            compareThisMonth: "Este Mes",
            value: "Febrero"
          },
          {
            name: "El mes pasado",
            compareLastMonth: "El mes pasado",
            value: "Enero"
          },
          {
            name: "Otro rango de fechas",
            compareRangeDate: "Otro rango de fechas",
            value: "Seleccionar"
          },
          {
            name: " ",
            compareWith: "Comparar con",
            disabled: true
          }
        ]
      },
      value2: [],
      value1: false,
      value3: false
    };
  },
  methods: {
    ...mapActions([
      "SET_COMMERCES",
      "SET_POINTSALES",
      "SET_CITIES",
      "SET_SELECTED_COMMERCE",
      "SET_SELECTED_POINTSALE",
      "SET_POINTSALE_BY_COMMERCE",
      "CLEAR_POINTSALES_BY_COMMERCE"
    ]),
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    Logout() {
      this.$store.commit("login", { isLogin: false });
      this.$router.push("/login");
      //window.location.href = "/";
    },
    handleClick() {
      alert("button click");
    },
    handleCommand(command) {
      if (command === "a") {
        this.$store.commit("login", { isLogin: false });
      }
    },
    changeSetting(setting) {
      this.selectedSetting = setting;
      // 
    },
    changeMonth(month) {
      this.selectedMonth = month;
      console.info(month, "month");
      this.$bus.$emit("changemonth", month);
    },
    async changeCommerce(selectedCommerce) {
      if (selectedCommerce == "Ninguno") {
        this.pointSalesData = {
          commerceRid: null,
          data: []
        };
        this.SET_SELECTED_COMMERCE({ name: selectedCommerce, rid: "T" });
      } else {
        this.SET_SELECTED_COMMERCE(selectedCommerce);
        this.SET_SELECTED_POINTSALE({});
        this.pointSalesData = this.pointSalesByCommerce.filter(
          pointsale => pointsale.commerceRid === selectedCommerce.rid
        )[0];
      }
    },
    changePointsale(selectedPointsale) {
      this.SET_SELECTED_POINTSALE(selectedPointsale);
    },
    async initDataStore() {
      this.SET_SELECTED_COMMERCE({});
      this.SET_SELECTED_POINTSALE({});

      const citiesRes = await ModelCities.GetCities();
      const pointSalesRes = await ModelPointSales.GetPointSales();
      const commerceRes = await ModelCommerce.GetCommerces();

      this.SET_CITIES(citiesRes);
      this.SET_COMMERCES(commerceRes);
      this.SET_POINTSALES(pointSalesRes);
      this.CLEAR_POINTSALES_BY_COMMERCE();

    },
    getMonthNumber(monthName) {
      const MONTHS = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ];
      let monthNumber;
      MONTHS.forEach((month, number) => {
        if (month.toLowerCase() === monthName.toLowerCase()) {
          monthNumber = number;
        }
      });
      return monthNumber;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
ul.el-menu {
  // border:1px solid red;
  overflow: hidden;
}
.el-menu--popup li {
  color: #fff !important;
}
.template .el-submenu__icon-arrow {
  margin-top: 9px !important;
}
.el-menu-up {
  display: flex;
  justify-content: space-between;
  background-color: var(--spdy-secondary-color) !important;
  figure {
    height: 19px;
    margin-right: auto;
    margin-top: 10px;
    text-align: center;
    img {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
// .el-input.el-input--medium.el-input--suffix input.el-input__inner{
//   background-color: red !important;
//   margin-top: 12px;
// }

.el-menu {
  border: 0px;
  background-color: var(--spdy-secondary-color);
}

p {
  display: inline;
}

.name {
  color: var(--spdy-primary-color);
}

.rol {
  color: white;
}

.el-icon-caret-bottom {
  font-size: 14px;
  color: white;
}

.el-dropdown-menu {
  background-color: var(--spdy-secondary-color);
  border: 0px;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: var(--spdy-secondary-color);
  color: var(--spdy-primary-color);
}

.el-submenu__title {
  color: #ffffff;
}
.el-dropdown {
  margin-top: -9px;
  margin-right: 5px;
  font-size: 14px;
  background: transparent;
  border-radius: 4px;
}

.bottomright {
  position: absolute;
  bottom: 8px;
  right: 40px;
  font-size: 18px;
  padding-left: 20px;
  color: white !important;
}
</style>