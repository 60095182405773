

import finalPin from 'raw-loader!@/helpers/pinFinalMap.txt'; 
import MultiStopPin from 'raw-loader!@/helpers/pinMultiStop.txt'; 
export const MultiStopPinMap = {
  data() {
    return {
    };
  },
  methods: {
    pinMultyStop (nStop) {
      let pinSvg = MultiStopPin.replace('number_to_replace', nStop);
      return pinSvg
    },
    pinMultyStopFinal (nStop){
      let pinMapBase64Svg = finalPin.replace('number_step_replace', nStop);
      return pinMapBase64Svg;
    },
    base64PinImage (nStop) {
      let pinMap = this.pinMultyStop (nStop);
      this.pinMultyStopFinal();
      return ('data:image/svg+xml,'+pinMap).trim();
    },
    base64PinFinalImage (nStop) {
      let pinMap = this.pinMultyStopFinal (nStop);
      return ('data:image/svg+xml,'+pinMap).trim();
    },
    
  }
};
export default MultiStopPinMap;