import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;


let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "schedule";

export class Schedule {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

   //commerce/rid/account
  GetSchedule(id) {
    let url = id != undefined ? `${API_URL}/${router}/${id}` : `${API_URL}/${router}`;
        
    console.warn(url);
    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /**
   * put Commerce by rid update
   * @param {string} rid
   */
  PutSchedule(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * post Commerce create
   * @param {string} rid
   */
  PostSchedule(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * delete Commerce remove
   * @param {string} rid
   */
  DeleteSchedule(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}