import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;


let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "zone";

export class Zones {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  //Rutas de Variables
  putVariablesZone(rid,data){
    const url = `${API_URL}/${router}/add-variables-rules/${rid}`
    return axios.put(url,{newVariables:data})
      .then(response => response.data)
      .catch((error)=> {
        return error.response.data
      })

  }

   postContactCommerce(rid, data){
    let url = `${API_URL}/${router}/${rid}/contact`;
      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

  GetZones(rid) {
    let url = rid == undefined ? `${API_URL}/${router}` : `${API_URL}/${router}/${rid}`;

    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  filterZone(filter){
    let url = `${API_URL}/zonefilter?fields=code,name&${filter}`;
    
    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
    }) 
  }

  filterCityZone(cityId){
    let url = `${API_URL}/zonefilter?city=${cityId}`;

    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
    }) 
  }

  filterDeliveryZone(filterId){
    let url = `${API_URL}/zonefilter?deliveryMens=${filterId}`;

    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
    })   
  }
  getInvalidZones(rid){
    let url = `${API_URL}/verifyschedule/${rid}`;

    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
    })  
  }

  getZonesFilters(filters){    
    let f = filters.fields;
    let c = filters.city
    let url = `${API_URL}/zonefilter?fields=${f}&city=${c}&active=true`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * put Commerce by rid update
   * @param {string} rid
   */
  PutZone(rid, data) {
    let url = `${API_URL}/${router}/${rid}`;
      console.log(url, "la url")
    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * post Commerce create
   * @param {string} rid
   */
  PostZone(data) {
    let url = `${API_URL}/${router}`

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * delete Commerce remove
   * @param {string} rid
   */
  DeleteZone(rid) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  

  PostUploadKml(data){
    //let url = `${API_URL}/${router}/${rid}`;
      
      console.error(data);

    // return axios.delete(url)
    //   .then(response => response.data)
    //   .catch((error) => {
    //     return error.response.data
    //   })


  }
}