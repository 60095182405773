import Vue from 'vue'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/es'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import VueCurrencyFilter from 'vue-currency-filter'
import * as VueGoogleMaps from 'vue2-google-maps'
import eventBus from './plugins/eventBus'
export const bus = new Vue();
import './plugins/sentry';
// map
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import DatetimePicker from 'vuetify-datetime-picker';
Vue.use(DatetimePicker);

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
});

const moment = require('moment')
require('moment/locale/es')

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBYraxoho-sfoQD1qCuNoErlvcrIM2Fn8A',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})

Vue.use(require('vue-moment'), {
  moment
})

import '@/styles/index.scss' // global css

import App from './app/App.vue'
import router from './router'
import store from './store'

import i18n from './i18n'

Vue.use(Element,  {
  size: 'medium', // set element-ui default size
  locale
})

Vue.use(eventBus)

Vue.config.productionTip = false

i18n.locale = "es"

router.beforeEach((to, from, next) => {
  const isLogin = store.state.isLogin;

  if ((isLogin && to.name !== "login")  || (!isLogin && to.name === "login") || to.name === "viewService") {
    next();
  } else if (!isLogin && to.name !== "login") {
    next({ path: "/login" });
  } else if (isLogin && to.name === "login") {
    next({ path: "/monitoring" });
  }
});
Vue.use(VueCurrencyFilter,
  {
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  }
)

new Vue({
  //mixins: [myMixin],
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})