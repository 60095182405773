<template>
<div class="login-container">

      <!-- Título -->
      <h1>Recuperar contraseña</h1>
      <!-- Formulario -->
      <el-form :model="passwordrecoverydata" ref="passwordrecovery" :rules="rules">
        <el-form-item
          
          prop="email">
            <el-input
              type="email"
              v-model="passwordrecoverydata.email"
              placeholder="E-mail"
              >
            </el-input>
        </el-form-item>

          <el-button type="primary" @click="showDialog=false">Enviar</el-button>
          

      </el-form>


</div>
</template>

<script>
  export default {
    name: 'passwordrecovery',
    data() {
      return {
        passwordrecoverydata: {
          email: ''
        },
        rules: {
          email: [
            { required: true, message: 'Debes ingresar una dirección de e-mail.', trigger: 'blur'},
            { type: 'email', message: 'Debe ser una dirección de e-mail.'}
          ]
        }
      }
    },
    methods: {
      cancelout(){
        this.$store.commit("login", {isLogin : false});
        this.$router.push('/')
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg:#161616;
  $light_gray:#434142;
  $cursor: #fff;

  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input{
      color: $cursor;
      &::first-line {
        color: $light_gray;
      }
    }
  }

  /* reset element-ui css */

  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        caret-color: $cursor;
        /* &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: $cursor !important;
        } */
      }
    }
    .el-form-item {
      border: 0px solid rgba(255, 255, 255, 0.1);
      background: var(--spdy-secondary-color);
      border-radius: 5px;
      color: #454545;
    }
    .el-button--primary {
      background-image: linear-gradient(to right, #4affa4 , #4aff48);
      border: 0px;
      font-weight: bold;
      color: #434142
    }
  }
</style>