import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let router = "pointsale";

export class PointSales {
 
    constructor() {
      axios.defaults.headers.common['x-access-token'] = store.state.authData.token
    }
  GetPdvZones(rid){
    let url = `${API_URL}/pointsale/pdv/${rid}`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  /**
   * GET all PointSale
   * @param {object} params filters, limit, page size
   */
  GetPointSales(rid) {
    let url = rid != undefined ? `${API_URL}/${router}/${rid}` : `${API_URL}/${router}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  GetPointSalesFilter(filter) {
    let url = `${API_URL}/pointsalefilter?${filter}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  getDeliveryMenFromPDV(rid){
    let url = `${API_URL}/${router}/deliverymanschedule/pointsale/${rid}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      });
  }
  /**
   * POST PointSale create
   * @param {string} rid
   */
  PostContactPointSales(rid, data) {
    let url = `${API_URL}/${router}/${rid}/contact`;
    
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  PostDatafonosPointSales(rid, data) {
    let url = `${API_URL}/${router}/${rid}/dataphone`;
    
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /**
   * POST PointSale create
   * @param {string} rid
   */
  PostPointSales(data) {
    let url = `${API_URL}/${router}`;
    
    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

   /**
   * POST PointSale create
   * @param {string} rid
   */
    PostSchedulesPointSales(data, rid) {
      let url = `${API_URL}/${router}/${rid}/schedule`;
      
      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
    }

  /**
  * PUT PointSale by rid update
  * @param {string} rid
  */
  PutPointSales(rid, data) {
    let url = `${API_URL}/${router}/${rid}`
    
    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
  
  /**
   * DELETE PointSale remove
   * @param {string} rid
   */
  DeletePointSales(rid) {
    let url = `${API_URL}/${router}/${rid}`
    console.log(url, "url");
    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}

