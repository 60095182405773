<template>
  <div class="container-monitoring" v-if="service">
    <el-card class="service-card" style="padding:0">
      <!-- Ajustamos las props para el tamaño dinámico del mapa -->
      <GoogleMap
        mapHeight="90vh"
        from="monitoring"
        themeMap= "true"
        watcher="0"
        :market="service.deliveryManPosition.location"
        :destination="gMap"
        :origin="pdvZone"
      />
    </el-card>
  </div>
</template>

<script>
import GoogleMap from "../../components/base/gmap/gmap.component.vue";
import { Monitor } from "@/services/monitor";
const ModelMonitor = new Monitor();

export default {
  name: "ServiceView",
  components: {
    GoogleMap,
  },
  data() {
    return {
      service: null,
      kmlZone: null,
      gMap: null,
      pdvZone: null,
    };
  },
  mounted() {
    const uuid = this.$route.params.uuid;
    if (uuid) {
      this.getService(uuid);
    }
  },
  methods: {
    async getService(uuid) {
      const data = await ModelMonitor.getServiceByUuid(uuid);
      console.log(data, "datos de getServiceByConsecutive");
      this.service = data.data;
      this.gMap = [{ location: this.service.destinationLocation.coordinates }];
      this.pdvZone = [{ location: this.service.locationPdv.coordinates }];
      const hour = this.service.logStates[0].date.split(" ");
      this.service.created = hour[1];
    },
  },
};
</script>

<style scoped>
.container-monitoring {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 100vh; /* Asegurarse que el contenedor ocupe toda la altura de la pantalla */
  width: 100%; /* Asegurarse que el contenedor ocupe todo el ancho de la pantalla */
}

.service-card {
  width: 100%;
  height: 90vh; /* Ajustar para que el mapa ocupe el 80% de la altura */
  padding: 0;
  margin: 0;
}
.service-card >>> .el-card__body {
  padding: 0 !important;
}

</style>
