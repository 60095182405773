import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "invoice-commerce";

export class InvoiceCommerces {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all commerces
   * @param {object} params filters, limit, page size
   */
  
   postInvoiceCommerce(data){
    let url = `${API_URL}/${router}`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

   PuInvoiceCommerce(data) {
    let url = `${API_URL}/${router}`;

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

 
  /**
   * get Commerce by rid
   * @param {string} rid
   */
  GetInvoiceCommerce( data_filter ) {
    let url = `${API_URL}/${router}/services`;
    console.log(url, "url")
    console.log(data_filter, "data_filter")
    return axios.get(url, { params: data_filter })
      .then(response => response.data)
      .catch((error) => {
        //console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  
  /*payment */
  GetPaymentCommerce( data_filter ) {
    let url = `${API_URL}/${router}`;
    //console.log(data_filter, "data_filter");
    return axios.get(url, { params: data_filter })
      .then(response => response.data)
      .catch((error) => {
        //console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }
}