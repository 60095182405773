import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

let config = {
  headers: {  
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':'X-Requested-With'
  }
};

let router = "t-commerce";

export class typeCommerces {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

   postTypeCommerces(data){
    let url = `${API_URL}/${router}`;

      return axios.post(url, data)
        .then(response => response.data)
        .catch((error) => {
          return error.response.data
        })
   }

   //commerce/rid/account
  GetTypeCommerces() {
    let url = `${API_URL}/${router}`;

    return axios.get(url, config)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * put Commerce by rid update
   * @param {string} rid
   */
  PutTypeCommerces(rid, data) {
    let url = `${API_URL}/${router}/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /**
   * delete Commerce remove
   * @param {string} rid
   */
  DeleteTypeCommerces(rid) {
    let url = `${API_URL}/${router}/${rid}`;

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}