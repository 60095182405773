<template>
  <div>
    <el-row>
      <el-col>
        <h1 class="text-center">Dashboard</h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <apexchart width="100%" type="bar" :options="options" :series="series"></apexchart>
        <apexchart width="100%" type=pie :options="piechartOptions" :series="pieseries" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <apexchart width="100%" type="line" :options="options" :series="series"></apexchart>
        <apexchart type=heatmap height=350 :options="heatChartOptions" :series="heatSeries" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

/* Función para heatmap */
function generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = 'w' + (i + 1).toString();
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }

export default {
  name: "report",
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example"
        },
        xaxis: {
          categories: ["Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", "Enero", "Febrero"]
        },
        title: {
            text: 'Crecimiento'
          }
      },
      piechartOptions: {
          labels: ['PPC', 'Frisby', "McDonald's", 'El Corral', 'Presto'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          title: {
            text: 'Ventas'
          }

        },
      heatChartOptions: {
          dataLabels: {
            enabled: false
          },
          colors: ["#008FFB"],
          title: {
            text: 'HeatMap Chart (Single color)'
          }
      },
      series: [
        {
          name: "2017-2018",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: "2018-2019",
          data: [40, 45, 50, 55, 54, 65, 75, 96]
        }
      ],
      pieseries: [44, 55, 13, 43, 22],
      heatSeries: [{
            name: 'Metric1',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric2',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric3',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric4',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric5',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric6',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric7',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric8',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          },
          {
            name: 'Metric9',
            data: generateData(18, {
              min: 0,
              max: 90
            })
          }
        ],
    }
  }
}
</script>