import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { sentry } from './settings';

if (sentry.enabled) {
  Vue.config.errorHandler = (err, vm, info) => {
    Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
    console.error(err);
  };

  Sentry.init({
    debug: false,
    dsn: sentry.dns,
    integrations: [new Sentry.Integrations.Vue({
      Vue,
      attachProps: true,
    })],
  });
}
