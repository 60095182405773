import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

export class BankTypeAcc {
  constructor() {
    //axios.defaults.timeout = 2000;
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all Bank Account Types
   * @param {object} params filters, limit, page size
   */
  GetBanksTypeAcc(params) {
    let url = `${API_URL}/type-acount-bank`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }


  /**
   * GET Bank Account Type by rid
   * @param {string} rid
   */
  GetBankTypeAcc(rid) {

    let url = `${API_URL}/type-acount-bank/${rid}`;
    
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log(error.response)
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT Bank Account Type by rid update
  * @param {string} rid
  */
  PutBankTypeAcc(rid, data) {
    let url = `${API_URL}/type-acount-bank/${rid}`

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Bank Account Type create
   * @param {string} rid
   */
  PostBankTypeAcc(data) {
    let url = `${API_URL}/type-acount-bank`

    console.log(url);
    console.log(data);

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        //console.log(error)
        return error ? error.response.data : undefined
      })
  }

  /**
   * DELETE Bank Account Type remove
   * @param {string} rid
   */
  DeleteBankTypeAcc(rid) {
    let url = `${API_URL}/type-acount-bank/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

}



