import axios from 'axios'
import store from '../store'

let configApi = require('../../config.json')[process.env.NODE_ENV]
const API_URL = configApi.VUE_APP_API;

export class Contacts {
  constructor() {
    axios.defaults.headers.common['x-access-token'] = store.state.authData.token
  }

  /**
   * GET all Contacts
   * @param {object} params filters, limit, page size
   */
  GetContacts(params) {
    let url = `${API_URL}/contact`

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * GET Contact by rid
   * @param {string} rid
   */
  GetContact(rid) {
    let url = `${API_URL}/contact/${rid}`;

    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        error.response.data.error = error.response.data.message
        return error.response.data
      })
  }

  /**
  * PUT Contact by rid update
  * @param {string} rid
  */
  PutContact(rid, data) {
    let url = `${API_URL}/contact/${rid}`

    if (data.name && data.nameLast && !data.fullname) {
      data.fullname = `${data.name} ${data.nameLast}`.trim()
    }

    return axios.put(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * POST Contact create
   * @param {string} rid
   */
  PostContact(data) {
    let url = `${API_URL}/contact`

    if (data.name && data.nameLast && !data.fullname) {
      data.fullname = `${data.name} ${data.nameLast}`.trim()
    }

    return axios.post(url, data)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }

  /**
   * DELETE Contact remove
   * @param {string} rid
   */
  DeleteContact(rid) {
    let url = `${API_URL}/contact/${rid}`

    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        return error.response.data
      })
  }
}
