<template src="./login.component.html"></template>

<script>
  import Vue from "vue";
  import { Users } from "@/services/User";
  import passwordrecovery from '@/components/User/PasswordRecoveryC.vue'
  import myMixin from '@/helpers/mixins.js';
  import loadingBase from '@/components/base/loading/loading.base.component.vue';
  import { mapActions, mapGetters } from 'vuex'

  const ModelUser = new Users();

  export default {
    name: "login",
    components: {
      passwordrecovery,
      loadingBase
    },
     mixins:[myMixin],
    data() {
      return {
        logindata: {
          username: "",
          password: ""
        },
        dataLogin: null,
        showDialog: false,
        loading: false,
        // Reglas de validación
        rules: {
          username: [
            { required: true, message: 'Campo requerido'},
            { type: '', message: 'Debe ser...'}
          ],
          password: [
            { required: true, message: 'Campo requerido'},
            { type: 'passsword', message: 'Debe ser...'}
          ]
        }
      };
    },
    methods: {
      ...mapActions(['SET_COMPLETE_LOGIN']),
      login() {
        this.loading = true;
        let username = this.logindata.username;
      if ( this.logindata.username != '' && this.logindata.password != '' ){
        ModelUser.Login( username.toLowerCase(), this.logindata.password)
          .then(data => {
            if(data.auth){
              if ( data.roles.length == 0 ){
                   myMixin.methods.setAlert("error",  "Este usuario no tiene nigún permiso");
                    this.loading = false;
                   return;
              }
                  this.$store.commit("login", { isLogin: true, authData: data});
                  this.$store.commit("SET_LOGIN_DATA", data );
                  this.$store.commit("SET_USER_DATA", { name: username })// Guarda el nombre del usuario
                  this.$router.push("/monitoring")
            }
            if ( data.auth == false ){
              myMixin.methods.setAlert("error",  data.error);
            }
            this.dataLogin = data;
            this.loading = false;
          })
          .catch(error => {
            this.dataLogin = error;
            this.$store.commit("login", { isLogin: false, authData: {} });
            this.loading = false;
          });
          }else{
            myMixin.methods.setAlert("error",  "No se permiten campos vacios");
            this.loading = false;
          }
      }
    }
  };
</script>
<style rel="stylesheet/scss" lang="scss">
  $bg:#161616;
  $light_gray:#434142;
  $cursor: #fff;

  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input{
      color: $cursor;
      &::first-line {
        color: $light_gray;
      }
    }
  }

.version-sistem{
  text-align: center;
  color: #797878;
  font-weight: bold;
  font-size: 12px;
}
  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
        input {
          background: transparent;
          border: 0px;
          -webkit-appearance: none;
          border-radius: 0px;
          padding: 12px 5px 12px 15px;
          color: $light_gray;
          height: 47px;
          caret-color: $cursor;
        }
      input:-webkit--internal-autofill-selected {
          background-color: transparent !important;
          background-image: none !important;
          color: rgb(0, 0, 0) !important;
      }
    }
    .el-form-item {
      border: 0px solid rgba(255, 255, 255, 0.1);
      background: #161616;
      border-radius: 5px;
      color: #fff;
      margin-bottom: 2px !important;
    }
    .el-button--primary {
      background-image: linear-gradient(to right, #4affa4 , #4aff48);
      border: 0px;
      font-weight: bold;
      color: #434142
    }
    .el-dialog {
      background: #1e1e1e
    }
  }
  .login-container .el-input input{
      color: #fff !important;
  }
  .login-container .el-input input::first-line{
      color: #fff !important;
  }    

  $bg:#1e1e1e;
  $dark_gray:#889aa4;
  $light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    position: relative;
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    width: 300px;
    vertical-align: middle;
    
  }
  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
      display: block;
      margin-left: auto;
      margin-right: auto
    }
    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size:18px;
      right: 0px;
      cursor: pointer;
    }
  }
  .forgot-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 14px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
  .bar {
    color: $bg;
    font-size: 16px;
  }
}
input.el-input__inner:-webkit-autofill,
input.el-input__inner:-webkit-autofill:hover, 
input.el-input__inner:-webkit-autofill:focus, 
input.el-input__inner:-webkit-autofill:active,
input.el-input__inner:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px #161616 inset !important;
    -webkit-text-fill-color: #fff !important;
    border-color: #161616;
}

input.el-input__inner:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px #161616 inset !important;
    -webkit-text-fill-color: #fff !important;
    border-color: #161616;
}

@media (max-width: 991px) {
   img.responsive-image{
     width: 150px;
   }
  }
</style>